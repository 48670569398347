// src/i18n.js
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationEN from './locales/en/translation.json';
import translationRO from './locales/ro/translation.json';
import translationES from './locales/es/translation.json';

// Language resources
const resources = {
  en: {
    translation: translationEN
  },
  ro: {
    translation: translationRO
  },
  es: {
    translation: translationES
  }
};

i18n
  .use(initReactI18next) // Passes i18n instance to react-i18next.
  .init({
    resources,
    lng: localStorage.getItem('i18nLang') || 'en', // Default language
    fallbackLng: 'en', // Fallback language if translation is missing
    interpolation: {
      escapeValue: false // React already escapes values
    }
  });

export default i18n;
