import { faCheck, faCheckCircle, faDiagramSuccessor } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { InlineWidget } from 'react-calendly';
import { useTranslation } from 'react-i18next';

function MeetPhone(props) {


  const { t } = useTranslation();

  return (
    <>
      <div className='flex-start flex-row gap-1' style={{ position: 'relative', width: '85%', paddingLeft: '1vw', paddingRight: '1vw', marginTop: '0vw', gap: '10vw', borderTopLeftRadius: '3vw', borderTopRightRadius: '3vw', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column' }}>
        <div style={{ fontSize: '10vw', fontWeight: 600, padding: '1vw', marginTop:'35vw', paddingLeft: '6vw', paddingRight: '6vw', borderRadius: '2vw', textAlign:'center' }}>{t('meeting_page.title')}</div>
        <div style={{ fontSize: '5vw', width: '90%', textAlign: 'center' }}>
          {t('meeting_page.description')}
        </div>
        <InlineWidget url="https://calendly.com/enevali2016/30min" styles={{ position: 'relative', width: '100%', height: '220vw' }} />
      </div>
    </>
  );
}

export default MeetPhone;
