/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useRef } from 'react'
import { useGLTF, useAnimations } from '@react-three/drei'

export function YTDObj(props) {
  const group = useRef()
  const { nodes, materials, animations } = useGLTF('/objects/ytd.glb')
  const { actions } = useAnimations(animations, group)
  return (
    <group ref={group} {...props} dispose={null}>
      <group name="Circular_Kinetic_Motion_Loop">

        <mesh
          name="Circle"
          castShadow
          receiveShadow
          geometry={nodes.Circle.geometry}
          material={materials.Blue}
        />
        <mesh
          name="Sphere_07"
          castShadow
          receiveShadow
          geometry={nodes.Sphere_07.geometry}
          material={materials.Metal}
          position={[-0.315, 0.032, 0.875]}
          rotation={[0, 1.225, 0]}
          scale={0.062}
        />
        <mesh
          name="Sphere_06"
          castShadow
          receiveShadow
          geometry={nodes.Sphere_06.geometry}
          material={materials.Metal}
          position={[0.679, 0.032, 0.419]}
          rotation={[-Math.PI, 0.553, -Math.PI]}
          scale={0.062}
        />
        <mesh
          name="Sphere_04"
          castShadow
          receiveShadow
          geometry={nodes.Sphere_04.geometry}
          material={materials.Metal}
          position={[0.505, 0.032, -0.168]}
          rotation={[Math.PI, -0.321, Math.PI]}
          scale={0.062}
        />
        <mesh
          name="Sphere_05"
          castShadow
          receiveShadow
          geometry={nodes.Sphere_05.geometry}
          material={materials.Metal}
          position={[0.091, 0.032, -0.658]}
          rotation={[Math.PI, -1.434, Math.PI]}
          scale={0.062}
        />
        <mesh
          name="Sphere_03"
          castShadow
          receiveShadow
          geometry={nodes.Sphere_03.geometry}
          material={materials.Metal}
          position={[-0.35, 0.032, 0.195]}
          rotation={[0, 0.508, 0]}
          scale={0.062}
        />
        <mesh
          name="Sphere_02"
          castShadow
          receiveShadow
          geometry={nodes.Sphere_02.geometry}
          material={materials.Metal}
          position={[-0.204, 0.032, -0.173]}
          rotation={[0, -0.701, 0]}
          scale={0.062}
        />
        <mesh
          name="Sphere_01"
          castShadow
          receiveShadow
          geometry={nodes.Sphere_01.geometry}
          material={materials.Metal}
          position={[0.074, 0.032, -0.111]}
          rotation={[Math.PI, -0.983, Math.PI]}
          scale={0.062}
        />
        <group name="Area" position={[1.261, 0.731, -0.724]} rotation={[-Math.PI / 2, 0, 0]} />
        <group name="Area001" position={[-0.18, 0.963, 0.518]} rotation={[-0.864, -0.047, 0.017]} />
      </group>
    </group>
  )
}

useGLTF.preload('/objects/ytd.glb')