import logo from './logo.svg';
import './App.css';
import './styles/home.css';
import Marquee from "react-fast-marquee";
import Navbar from './Navbar';
import { useEffect, useState } from 'react';
import { I18nextProvider, useTranslation } from 'react-i18next';
import Home from './Home';
import NewHome from './NewHome';
import NavbarPhone from './NavbarPhone';
import NewHomePhone from './NewHomePhone';
import Contact from './Contact.jsx';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Meet from './Meet.jsx';
import ContactPhone from './ContactPhone.jsx';
import MeetPhone from './MeetPhone.jsx';


function App() {
  // Scroll to the top instantly when the component mounts
  useEffect(() => {
    const pixelOffset = 0 * window.innerWidth / 100; // Convert vw to pixels
    window.scrollTo({
      top: pixelOffset,
      behavior: 'smooth' // For smooth scrolling
    });
  }, []);
  const [scrollPosition, setScrollPosition] = useState(0);

  const updateScrollPosition = () => {
    const totalHeight = document.documentElement.scrollHeight - window.innerHeight; // Total scrollable height
    const currentScrollY = window.scrollY; // Current scroll position
    const normalizedScrollPosition = totalHeight ? (currentScrollY / totalHeight) * 100 : 0; // Normalized scroll position as a percentage
    setScrollPosition(normalizedScrollPosition);
  };

  useEffect(() => {
    window.addEventListener('scroll', updateScrollPosition);
    return () => {
      window.removeEventListener('scroll', updateScrollPosition);
    };
  }, []);


  const [windowWidth, setWindowWidth] = useState(window.innerWidth);

  useEffect(() => {
    function handleResize() {
      setWindowWidth(window.innerWidth);
    }

    // Add event listener for window resize
    window.addEventListener('resize', handleResize);

    // Clean up the event listener when the component unmounts
    return () => {
      window.removeEventListener('resize', handleResize);
    };
  }, []);

  return (
    <I18nextProvider>
      <Router>
        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', left: 0, flexDirection: 'column' }}>
          {
            windowWidth > 1060 ? (
              <>
                <Navbar scrollPosition={scrollPosition} />
                <Routes>
                  <Route path="/" element={<NewHome scrollPosition={scrollPosition} />} />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/meet" element={<Meet />} />
                </Routes>
              </>
            ) : (
              <>
                <NavbarPhone scrollPosition={scrollPosition} />
                <Routes>
                  <Route path="/" element={<NewHomePhone scrollPosition={scrollPosition} />} />
                  <Route path="/contact" element={<ContactPhone />} />
                  <Route path="/meet" element={<MeetPhone />} />
                </Routes>
              </>
            )
          }
        </div>
      </Router>
    </I18nextProvider>
  );
}

export default App;
