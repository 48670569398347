import logo from './logo.svg';
import './App.css';
import './styles/home.css';
import Marquee from "react-fast-marquee";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faComments } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';

function NavbarPhone(props) {

    const [show, setShow] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    const controlNavbar = () => {
        // console.log("ok")
        if (window.scrollY > lastScrollY && window.scrollY > 2) {
            setShow(false);
        } else {
            setShow(true);
        }
        setLastScrollY(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', controlNavbar);

        return () => {
            window.removeEventListener('scroll', controlNavbar);
        };
    }, [lastScrollY]);

    const { t, i18n } = useTranslation();

    const [language, setLanguage] = useState(i18n.language ? i18n.language : "en");

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setLanguage(lng);
        localStorage.setItem('i18nLang', lng);
    };



    const scrollToBottom = () => {
        window.scrollTo({
            top: document.documentElement.scrollHeight,
            behavior: 'smooth'
        });
    };
    const navigate = useNavigate();

    return (
        <>
            <div
                className={`${show ? 'flex-center flex-row navbar show' : 'flex-center flex-row navbar'}`}
                style={{ position: 'fixed', justifyContent: 'space-between', width: '100%', zIndex: 999, borderBottomLeftRadius: '8vw', borderBottomRightRadius: '8vw', backgroundColor: 'white' }}
            >
                <div onClick={()=>{navigate('/')}} style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
                    <img
                        src="logo.png"
                        style={{
                            width: '18vw',
                            position: 'relative',
                            filter: 'invert(0)',
                        }}
                    />
                    <div
                        style={{
                            fontWeight: 600,
                            cursor: 'pointer',
                            zIndex: 1,
                            position: 'relative',
                            fontSize: '4vw',
                            textAlign: 'start',
                            color: 'black',
                        }}
                    >
                        YTD Real Consulting
                    </div>
                </div>

                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '4vw' }}>
                    <div
                        onClick={() => { navigate('/meet') }}
                        className="fs-paragraph book-meeting"
                        style={{
                            fontWeight: 600,
                            cursor: 'pointer',
                            zIndex: 1,
                            transition: 'all ease-in-out 0.3s',
                            color: 'black',
                        }}
                    >
                        <FontAwesomeIcon icon={faComments} style={{ fontSize: '5vw' }} />
                    </div>

                    <div
                        className="btn-language flex-center"
                        style={{
                            fontWeight: 600,
                            cursor: 'pointer',
                            zIndex: 1,
                            overflow: 'hidden',
                            width: '7vw',
                            height: '7vw',
                            marginRight: '5vw',
                            opacity: 1
                        }}
                    >
                        {language === 'ro' ? (
                            <img
                                src="flags/1.svg"
                                onClick={() => changeLanguage('es')}
                                style={{ position: 'absolute', userSelect: 'none', height: '100%' }}
                            />
                        ) : language === 'es' ? (
                            <img
                                src="flags/2.svg"
                                onClick={() => changeLanguage('en')}
                                style={{ position: 'absolute', userSelect: 'none', height: '100%' }}
                            />
                        ) : language === 'en' ? (
                            <img
                                onClick={() => changeLanguage('ro')}
                                src="flags/3.webp"
                                style={{ position: 'absolute', userSelect: 'none', height: '100%' }}
                            />
                        ) : null}
                    </div>
                </div>
            </div>

        </>
    );
}

export default NavbarPhone;
