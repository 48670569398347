import { faCheck, faCheckCircle, faDiagramSuccessor } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import './index.css'

function Contact(props) {
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    message: '',
  });

  const [errors, setErrors] = useState({});

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  const [nameOk, setNameOk] = useState(true);
  const [emailOk, setEmailOk] = useState(true);
  const [phoneOk, setPhoneOk] = useState(true);
  const [messageOk, setMessageOk] = useState(true);

  const validatePhone = (phone) => {
    const phonePattern = /^[0-9+\-() ]+$/; // Regex to allow numbers, +, -, (, ) and spaces
    return phonePattern.test(phone);
  };

  const botToken = '7761694528:AAE01azzxxynkYzZ6g2NdF7A3ABIy68aonQ'; // Replace with your bot token
  const chatId = '-4528426666'; // Replace with your chat ID or username

  const validateForm = () => {
    const newErrors = {};
    if (!formData.name) {
      setNameOk(false)
      return false;
    }
    if (!formData.email) {
      setEmailOk(false)
      return false;
    }
    else if (!/\S+@\S+\.\S+/.test(formData.email)) {
      setEmailOk(false)
      return false;
    }
    if (!formData.phone || !validatePhone(formData.phone)) {
      setPhoneOk(false);
      return false;
    }
    if (!formData.message) {
      setMessageOk(false)
      return false;
    }
    return true;
  };

  const url = `https://api.telegram.org/bot${botToken}/sendMessage`;
  const [showNotification, setShowNotification] = useState(false);
  const handleSubmit = async (e) => {
    setNameOk(true)
    setEmailOk(true)
    setPhoneOk(true)
    setMessageOk(true)


    const isFormOk = validateForm();
    if (isFormOk) {
      console.log("formdata:", formData)
      let message = formData.name + "\n\n" + formData.message + "\n\n" + formData.email + "\n" + formData.phone

      try {
        const response = await fetch(url, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({
            chat_id: chatId,
            text: message,
          }),
        });

        if (response.ok) {
          console.log("Msg sent.")
          setShowNotification(true);
          setTimeout(() => {
            setShowNotification(false);
          }, 4000)
        } else {
          throw new Error('Failed to send message');
        }
      } catch (error) {
        console.log(error)
      }

      setFormData({ name: '', email: '', phone: '', message: '' });
    }
  };

  const { t } = useTranslation();

  return (
    <>

      <div className='flex-center flex-row gap-1' style={{ width: '100%', minHeight: '100vh', position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
        <div className={showNotification ? 'notification show' : 'notification'} style={{ position: 'absolute', display: 'flex', maxWidth: '20vw', justifyContent: 'center', alignItems: 'center', flexDirection: 'row', gap: '1vw', fontSize: '1vw', bottom: '1vw', right: '2vw', backgroundColor: 'rgba(0,0,0,1)', padding: '1vw', paddingLeft: '1.5vw', paddingRight: '1.5vw', borderRadius: '3vw' }}>
          <FontAwesomeIcon icon={faCheck} style={{ fontSize: '1.5vw', color:'white' }} />
          <div style={{ position: 'relative', width: '100%', textAlign: 'start', color:'white' }}>{t('contact_page.alert')}</div>
        </div>

        <div className='flex-center flex-row gap-1' style={{ width: '100%', maxWidth: '30vw', minHeight: '100vh', position: 'relative', display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
          <h1 style={{ position: 'relative', width: '100%', textAlign: 'center', fontSize: '3vw' }}>{t('contact_page.title')}</h1>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '1vw' }}>
            <input
              type="text"
              id="name"
              name="name"
              placeholder={t('contact_page.name')}
              value={formData.name}
              onChange={handleChange}
              style={{ position: 'relative', width: '100%', fontFamily:'Raleway', borderRadius: '3vw', outline: 'none', border: 'none', padding: '0.5vw', paddingLeft: '1vw', paddingRight: '1vw', fontSize: '1.1vw', backgroundColor: '#ebf3ff', color: nameOk ? '#0060e6' : '#ff0000' }}
              required
            />
            <input
              placeholder={t('contact_page.email')}
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              style={{ position: 'relative', width: '100%', fontFamily:'Raleway', borderRadius: '3vw', outline: 'none', border: 'none', padding: '0.5vw', paddingLeft: '1vw', paddingRight: '1vw', fontSize: '1.1vw', backgroundColor: '#ebf3ff', color: emailOk ? '#0060e6' : '#ff0000' }}
              required
            />
            <input
              placeholder={t('contact_page.phone')}
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              style={{ position: 'relative', width: '100%', fontFamily:'Raleway', borderRadius: '3vw', outline: 'none', border: 'none', padding: '0.5vw', paddingLeft: '1vw', paddingRight: '1vw', fontSize: '1.1vw', backgroundColor: '#ebf3ff', color: phoneOk ? '#0060e6' : '#ff0000' }}
            />
            <textarea
              id="message"
              name="message"
              value={formData.message}
              onChange={handleChange}
              placeholder={t('contact_page.message')}
              style={{ position: 'relative', width: '100%', fontFamily:'Raleway', minHeight: '10vw', borderRadius: '2vw', outline: 'none', border: 'none', padding: '0.5vw', paddingLeft: '1vw', paddingRight: '1vw', fontSize: '1.1vw', backgroundColor: '#ebf3ff', color: messageOk ? '#0060e6' : '#ff0000' }}
              required
            />
          </div>
          <div style={{ width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'end', flexDirection: 'column', gap: '1vw' }}>
            <button className='sendBtn' onClick={(e) => { handleSubmit(e) }} style={{ position: 'relative', outline: 'none', border: 'none', fontSize: '1.1vw', borderRadius: '3vw', padding: '0.5vw', paddingLeft: '1vw', paddingRight: '1vw' }}>{t('contact_page.send')}</button>
          </div>
        </div>

      </div>
    </>
  );
}

export default Contact;
