import logo from './logo.svg';
import './App.css';
import './styles/home.css';
import Marquee from "react-fast-marquee";
import Navbar from './Navbar';
import React, { Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { Canvas, events, useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { Pig } from './Pig';
import { CameraControls, Cloud, CloudInstance, Clouds, Environment, OrbitControls, PerspectiveCamera, PointMaterial, Points, PresentationControls } from '@react-three/drei';
import DotsInSphere from './DotsInSphere';
import { useSpring, animated } from 'react-spring';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleDown, faArrowDown, faCaretDown, faCircleInfo, faCube, faEye, faHourglass, faHourglass3, faInfo, faInfoCircle, faMagnifyingGlass, faMagnifyingGlassChart, faPaperclip, faPeopleGroup, faPlus, faQuoteLeft, faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import { InlineWidget } from 'react-calendly';
import { faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { YTDObj } from './YTDObj';
import { useControls } from 'leva';
import { useNavigate } from 'react-router-dom';


const Lights = React.memo(() => {
  // State to store mouse position
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });

  // Update mouse position on mouse move
  useEffect(() => {
    const handleMouseMove = (event) => {
      const x = (event.clientX / window.innerWidth) * 2 - 1; // Normalize to [-1, 1]
      const y = -(event.clientY / window.innerHeight) * 2 + 1; // Normalize to [-1, 1]
      setMousePos({ x, y });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  // Light positions with offsets based on mouse position
  const lightOffset = 0.5; // Adjust this value for the intensity of the offset
  const lights = [
    { intensity: 10, position: [0, 3, 0] },
    { intensity: 5, position: [-5, 0, 3] },
    { intensity: 10, position: [0, -3, 0] },
    { intensity: 3, position: [-2, -1, 0], color: "rgb(202, 15, 46)" },
    { intensity: 2, position: [0, 1, 2], color: "white" },
  ];

  return (
    <>
      {lights.map((light, index) => (
        <pointLight
          key={index}
          color={light.color || "white"}
          intensity={light.intensity}
          position={[
            light.position[0] + mousePos.x * lightOffset,
            light.position[1] + mousePos.y * lightOffset,
            light.position[2],
            light.position[3],
          ]}
        />
      ))}
    </>
  );
});


const ProgressLine = ({ scrollPosition }) => {
  const pathLength = 50;

  const lineStyle = useSpring({
    strokeDashoffset: scrollPosition * 10,
    strokeDasharray: pathLength,
    config: { tension: 100, friction: 20 },
  });

  return (
    <div>
      <svg
        style={{
          width: '130vw',
          height: 'auto',
          zIndex: 2,
          position: 'absolute',
          left: '-8vw',
          top: '-3vw',
          pointerEvents: 'none',
          userSelect: 'none',
        }}
        width="431"
        height="464"
        viewBox="-50 -50 531 564"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <animated.path
          d="M1.03733 132.492C1.0373 30.6262 52.9845 70.3983 142.269 43.613C231.554 16.8277 195.028 0.999992 289.589 1C301.476 1 346 1 346 1M1.03733 132.492C1.03735 180.38 1.03732 208.383 1.03732 208.383M1.03733 132.492H118.731M1.03732 208.383H201.928M1.03732 208.383L1.03734 268.853M1.03734 268.853H71.2477M1.03734 268.853L1.03743 361.28M1.03743 361.28H121.019M1.03743 361.28V399.28V466"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          style={lineStyle}
        />
      </svg>
    </div>
  );
};



function Sky() {
  const ref = useRef()
  useFrame((state, delta) => {
    ref.current.rotation.y = Math.cos(state.clock.elapsedTime / 2) / 2
    ref.current.rotation.x = Math.sin(state.clock.elapsedTime / 2) / 2
  })
  return (
    <>
      <group ref={ref}>
        <Clouds limit={50}>
          <Cloud bounds={[20, 5, 1]} color="#ffffff" opacity={0.1} position={[-0, 0, 0]} />
        </Clouds>
      </group>
    </>
  )
}


function NewHome(props) {

  const memoizedSky = useMemo(() =>
    <Sky />
    , [])

  const memoizedSphere = useMemo(() =>
    <DotsInSphere position={[-2.5, 0, 0]} />
    , [])

  const styles = useSpring({
    position: 'absolute',
    left: '-45vw',
    top: '35vw',
    width: '100vw',
    transform: `rotate(25deg) translateY(${-(Math.max(props.scrollPosition * 0.5, -0.1))}vw)`,
    config: { tension: 170, friction: 26 }, // You can tweak the animation speed with these settings
  });

  //style={{ position: 'absolute', width: '50%', top: '10vw', left: '70vw', transform:'rotate(-90deg)' }}
  const styles2 = useSpring({
    position: 'absolute',
    left: '75vw',
    top: '25vw',
    width: '50vw',
    transform: `rotate(-90deg) translateY(${-(Math.max(props.scrollPosition * 0.3, -0.1))}vw)`,
    config: { tension: 170, friction: 26 }, // You can tweak the animation speed with these settings
  });

  useEffect(() => {
    // console.log("scroll: ", props.scrollPosition)
  }, [props.scrollPosition])
  const { t } = useTranslation();
  const navigate = useNavigate();
  return (<>
    <div className='flex-center flex-column gap-1' style={{ width: '100%', minHeight: '100vh' }}>
      <div className='flex-center flex-row gap-1' style={{ width: '100%', minHeight: '100vh', position: 'relative', display: 'flex' }}>
        {/* <div className='flex-center' style={{ position: 'absolute', width: '100%', height: '100%', zIndex: 0 }}>
          <div style={{ position: 'absolute', zIndex: -1, marginLeft: '-40vw', top: '7vw', userSelect: 'none', pointerEvents: 'none', height: '12vw', width: '12vw', borderRadius: '50%', backgroundColor: 'var(--ytd-background-green-color)', filter: 'blur(5rem)', opacity: '0.6' }} className='animation-orange1'></div>
          <div style={{ position: 'absolute', zIndex: -1, marginLeft: '40vw', top: '15vw', userSelect: 'none', pointerEvents: 'none', height: '12vw', width: '12vw', borderRadius: '50%', backgroundColor: 'var(--ytd-background-green-color)', filter: 'blur(5rem)', opacity: '0.6' }} className='animation-orange2'></div>
        </div> */}

        <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', flexDirection: 'column', overflow: 'hidden' }}>
          <Canvas
            gl={{
              antialias: true,
              toneMapping: THREE.NoToneMapping,
              powerPreference: "high-performance",
              alpha: true,
              stencil: false,
            }}
            dpr={[1, 1.5]} // Limits device pixel ratio between 1 and 1.5 for better performance
            camera={{ position: [0, 0, 20], fov: 15 }}
            style={{ position: 'relative', height: '100%', width: '100%', zIndex: 1 }}
          >
            <PresentationControls
              enabled={true} // the controls can be disabled by setting this to false
              global={true} // Spin globally or by dragging the model
              cursor={false} // Whether to toggle cursor style on drag
              snap={true} // Snap-back to center (can also be a spring config)
              speed={1} // Speed factor
              zoom={1} // Zoom factor when half the polar-max is reached
              rotation={[0, 0, 0]} // Default rotation
              polar={[-Math.PI / 2, Math.PI / 2]} // Vertical limits
              azimuth={[-Math.PI / 10, Math.PI / 10]} // Horizontal limits
              config={{ mass: 1, tension: 170, friction: 26 }} // Spring config
            >
              {memoizedSphere}
            </PresentationControls>
          </Canvas>
        </div>

        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '45%', height: '100%', flexDirection: 'column', overflow: 'hidden' }}>
        </div>

        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '45%', height: '100%', flexDirection: 'column' }}>
          <div className='flex-center' style={{ fontWeight: 600, fontSize: '3vw', color: 'black', width: '100%', marginTop: '0vw', zIndex: 1, opacity: 1, height: '100%' }}>{t('home.title')}</div>
          <div className='flex-center flex-column' style={{ fontWeight: 300, maxWidth:'35vw', height: '100%', fontSize: '1.4vw', color: 'black', width: '100%', borderBottom:'solid 0.4vw #ca0f2e', zIndex: 3, backgroundColor: 'rgba(0,0,0,0.02)', backdropFilter: 'blur(0.1vw)', WebkitBackdropFilter: 'blur(0.1vw)', top: '4vw', padding: '1vw', paddingTop: '1.5vw', paddingBottom: '2.7vw', borderRadius: '2vw' }}>
            {t('home.description')}
          </div>
          
          <div className='flex-center' style={{ fontWeight: 600, fontSize: 25, color: 'black', width: '100%', top: '2.2vw', height: '100%', zIndex:3 }}>
            <div className='contactBtn' onClick={()=>{navigate('/contact')}} style={{position:'relative', cursor:'pointer', borderRadius:'6vw'}}>{t('home.contact')}</div>
          </div>
        </div>
        <div style={{ position: 'absolute', width: '100%', bottom: '3vw', fontWeight: 600, opacity: 0.5, fontSize:'1vw' }}>{t('home.scroll')}</div>
        <div style={{ position: 'absolute', width: '100%', bottom: '1.5vw', opacity: 0.5, fontSize:'1vw' }}><FontAwesomeIcon icon={faArrowDown} /></div>
      </div>

      <div className='flex-start flex-column gap-1' style={{ position: 'relative', height: '340vw', width: '100%', marginTop: '5vw' }}>
        <div className='container-1 flex-start flex-column gap-1' style={{ height: '210vw' }}>

          <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', flexDirection: 'column', overflow: 'hidden', backgroundImage: 'url(/background/dots_white.png)', opacity: 0.1 }}>
          </div>
          <animated.img style={styles} src='objects/metal1.png' />
          <animated.img style={styles2} src='objects/metal1.png' />
          <div className='flex-center flex-column gap-1' style={{ color: 'white', textAlign: 'center', alignItems: 'center', width: '100%', top: '1rem', position: 'relative' }}>
            <div className='flex-center flex-column gap-1' style={{ color: 'white', textAlign: 'center', alignItems: 'center', width: '100%', position: 'relative' }}>
              <div className={props.scrollPosition > 5 ? 'container1-subtitle show flex-center' : 'container1-subtitle flex-center'} style={{ position: 'relative', fontWeight: 200, textAlign: 'center' }}>
                <div style={{ position: 'relative', textAlign: 'center', fontSize: '1vw', padding: '0.3vw', paddingLeft: '1vw', paddingRight: '1vw', border: 'solid 0.1vw', borderRadius: '4vw', backgroundColor: 'rgba(255,255,255,0.05)' }}>
                  <div style={{ color: 'white' }}>{t('optimization_page.section.header')}</div>
                </div>
              </div>
              <div className={props.scrollPosition > 5 ? 'container1-title show' : 'container1-title'} style={{ position: 'relative', fontSize: '3.5vw', fontWeight: 600, width: '100%', textAlign: 'center' }}>{t('optimization_page.title')}</div>
              <div className='flex-center flex-row' style={{ position: 'relative', fontWeight: 600, top: '6vw', width: '55vw', justifyContent: 'center', alignItems: 'start', gap: '4vw', height: '35vw' }}>
                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                  <div className={props.scrollPosition > 18 ? 'flex-start flex-column gap-1 box-card semishow' : props.scrollPosition > 9 ? 'flex-start flex-column gap-1 box-card show' : 'flex-start flex-column gap-1 box-card'} style={{ width: '100%', borderRadius: '2vw', position: 'absolute', padding: '1.5vw', alignItems: 'start' }}>
                    <div style={{ fontSize: '2vw', textAlign: 'start', opacity: 0.5 }}><FontAwesomeIcon icon={faQuoteLeft} /></div>
                    <div style={{ fontSize: '1.5vw', textAlign: 'start', marginTop: '0vw' }}>
                      {t('optimization_page.cards.card1.description')}
                    </div>
                    <div className='flex-start flex-row' style={{ fontSize: '1vw', textAlign: 'start', borderRadius: '2vw', padding: '0.5rem', gap: '0.5rem', paddingLeft: '1.5rem', paddingRight: '1.5rem', color: 'black', marginTop: '0.5rem' }}><div style={{ position: 'relative', marginLeft: '-1rem', height: '0.7vw', width: '0.7vw', borderRadius: '50%', backgroundColor: "var(--ytd-background-green-color)" }}></div>
                      {t('optimization_page.cards.card1.hint')}</div>
                  </div>
                  <div className={props.scrollPosition > 18 ? 'flex-start flex-column gap-1 box-card show' : 'flex-start flex-column gap-1 box-card'} style={{ width: '100%', borderRadius: '2vw', position: 'absolute', padding: '1.5vw', alignItems: 'start', marginTop: '4vw' }}>
                    <div style={{ fontSize: '2vw', textAlign: 'start', opacity: 0.5 }}><FontAwesomeIcon icon={faQuoteLeft} /></div>
                    <div style={{ fontSize: '1.5vw', textAlign: 'start', marginTop: '0vw' }}>
                      {t('optimization_page.cards.card3.description')}</div>
                    <div className='flex-start flex-row' style={{ fontSize: '1vw', textAlign: 'start', borderRadius: '2vw', padding: '0.5rem', gap: '0.5rem', paddingLeft: '1.5rem', paddingRight: '1.5rem', color: 'black', marginTop: '0.5rem' }}><div style={{ position: 'relative', marginLeft: '-1rem', height: '0.7vw', width: '0.7vw', borderRadius: '50%', backgroundColor: "var(--ytd-background-green-color)" }}></div>
                      {t('optimization_page.cards.card3.hint')}</div>
                  </div>
                </div>
                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                  <div className={props.scrollPosition > 22 ? 'flex-start flex-column gap-1 box-card semishow' : props.scrollPosition > 14 ? 'flex-start flex-column gap-1 box-card show' : 'flex-start flex-column gap-1 box-card'} style={{ width: '100%', borderRadius: '2vw', position: 'absolute', padding: '1.5vw', alignItems: 'start' }}>
                    <div style={{ fontSize: '2vw', textAlign: 'start', opacity: 0.5 }}><FontAwesomeIcon icon={faQuoteLeft} /></div>
                    <div style={{ fontSize: '1.5vw', textAlign: 'start', marginTop: '0vw' }}>
                      {t('optimization_page.cards.card2.description')}</div>
                    <div className='flex-start flex-row' style={{ fontSize: '1vw', textAlign: 'start', borderRadius: '2vw', padding: '0.5rem', gap: '0.5rem', paddingLeft: '1.5rem', paddingRight: '1.5rem', color: 'black', marginTop: '0.5rem' }}><div style={{ position: 'relative', marginLeft: '-1rem', height: '0.7vw', width: '0.7vw', borderRadius: '50%', backgroundColor: "var(--ytd-background-green-color)" }}></div>
                      {t('optimization_page.cards.card2.hint')}</div>
                  </div>

                  <div className={props.scrollPosition > 22 ? 'flex-start flex-column gap-1 box-card show' : 'flex-start flex-column gap-1 box-card'} style={{ width: '100%', borderRadius: '2vw', position: 'absolute', padding: '1.5vw', alignItems: 'start', marginTop: '4vw' }}>
                    <div style={{ fontSize: '2vw', textAlign: 'start', opacity: 0.5 }}><FontAwesomeIcon icon={faQuoteLeft} /></div>
                    <div style={{ fontSize: '1.5vw', textAlign: 'start', marginTop: '0vw' }}>
                      {t('optimization_page.cards.card4.description')}</div>
                    <div className='flex-start flex-row' style={{ fontSize: '1vw', textAlign: 'start', borderRadius: '2vw', padding: '0.5rem', gap: '0.5rem', paddingLeft: '1.5rem', paddingRight: '1.5rem', color: 'black', marginTop: '0.5rem' }}><div style={{ position: 'relative', marginLeft: '-1rem', height: '0.7vw', width: '0.7vw', borderRadius: '50%', backgroundColor: "var(--ytd-background-green-color)" }}></div>
                      {t('optimization_page.cards.card4.hint')}
                    </div>
                  </div>
                </div>
              </div>
              <div className='flex-center flex-row' style={{ position: 'relative', fontWeight: 600, top: '2.5vw', width: '50vw', justifyContent: 'center', alignItems: 'center' }}>
                <div className={props.scrollPosition > 37.5 ? 'flex-start flex-column gap-1 box-card showGrey' : 'flex-start flex-column gap-1 box-card'} style={{ width: '100%', borderRadius: '2vw', position: 'relative', alignItems: 'center' }}>
                  <div style={{ fontSize: '2vw', textAlign: 'center', marginTop: '-3vw', padding: '1vw', borderRadius: '50%' }}><FontAwesomeIcon icon={faCircleInfo} /></div>
                  <div style={{ fontSize: '1.3vw', textAlign: 'center', marginTop: '-1.5vw' }}>
                    {t('optimization_page.section.footer')}
                  </div>
                  <div style={{ fontSize: '1.3vw', textAlign: 'center', marginTop: '-0.5vw' }}>
                    {t('optimization_page.section.footer2')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* PIGGY SECTION */}
        <div className='flex-start flex-column gap-1' style={{ position: 'sticky', top: '1vw', background: '#141416', width: '99%', height: '140vw', borderRadius: '3vw', marginTop: '-20vw', overflow: 'hidden' }}>
          <img src='background/2.png' style={{ opacity: 1, left: '-70vw', position: 'absolute', top: '20vw', width: '100vw', height: '100vw' }} />
          <img src='background/2.png' style={{ opacity: 1, right: '-70vw', position: 'absolute', top: '70vw', width: '100vw', height: '100vw' }} />

          <Canvas
            gl={{
              antialias: true,
              toneMapping: THREE.NoToneMapping,
              powerPreference: "high-performance",
              alpha: true,
              stencil: false,
            }}
            linear
            dpr={[1, 1.5]} // Limits device pixel ratio between 1 and 1.5 for better performance
            camera={{ position: [0, 0, 20], fov: 15 }}
            style={{ position: 'absolute', width: '100%', zIndex: 4, backgroundColor: 'transparent', pointerEvents: 'none' }}
          >
            <Lights />
            <Suspense fallback={null}>
              <Pig scale={4} position={[-0.6, 0.9, 0]} rotation={[0, 0.2, 0.3]} />
            </Suspense>
          </Canvas>
          <div className='flex-end flex-column gap-1' style={{ color: 'white', width: '100%', alignItems: 'end', top: '10vw' }}>
            <div className='flex-end flex-column gap-1' style={{ color: 'white', width: '100%', maxWidth: '40vw', right: '6vw' }}>
              <div className='flex-end flex-column gap-1' style={{ color: 'white', width: '100%', fontSize: '3.5vw', textAlign: 'end', fontWeight: 600, zIndex: 4, display: 'inline' }}>
                {t('funds_page.title')}
              </div>
              <div className='flex-end flex-column gap-1' style={{ color: 'white', width: '100%', fontSize: '1.5vw', textAlign: 'end', fontWeight: 500, zIndex: 4 }}>
                {t('funds_page.description')}
              </div>

              <div style={{ position: 'absolute', width: '100vw', top: '13vw', left: '-51vw', zIndex: 2 }}>
                <ProgressLine scrollPosition={props.scrollPosition} />

                <svg style={{ width: '130vw', height: 'auto', position: 'absolute', left: '-8vw', top: '-3vw', pointerEvents: 'none', userSelect: 'none' }} width="431" height="464" viewBox="-50 -50 531 564" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M1.03733 132.492C1.0373 30.6262 52.9845 70.3983 142.269 43.613C231.554 16.8277 195.028 0.999992 289.589 1C301.476 1 346 1 346 1M1.03733 132.492C1.03735 180.38 1.03732 208.383 1.03732 208.383M1.03733 132.492H118.731M1.03732 208.383H201.928M1.03732 208.383L1.03734 268.853M1.03734 268.853H71.2477M1.03734 268.853L1.03743 361.28M1.03743 361.28H121.019M1.03743 361.28V399.28V466"
                    stroke="#252729"
                    strokeWidth="2"
                    strokeLinecap="round"
                  />
                </svg>

              </div>
              <div style={{ position: 'absolute', width: '100vw', top: '13vw', left: '-51vw' }}>
                <div style={{ position: 'absolute', height: '3vw', width: '3vw', borderRadius: '50%', backgroundColor: 'white', top: '9.1vw', right: '9vw', zIndex: 3 }}></div>

                <div className={props.scrollPosition > 56 ? 'flex-start flex-column gap-1 descriptionContainer show' : 'flex-start flex-column gap-1 descriptionContainer'} style={{ position: 'absolute', backgroundColor: '#252729', left: '32vw', top: '41.1vw', color: 'white', padding: '1vw', borderRadius: '1.5vw', alignItems: 'start', fontWeight: 300 }}>
                  <div className='flex-start flex-row gap-1' style={{ position: 'relative', fontSize: '1vw', zIndex: 2 }}>
                    <div style={{ position: 'relative', height: '1vw', width: '1vw', backgroundColor: 'white', borderRadius: '50%', boxShadow: '0px 0px 15px 1px white' }}></div>
                  </div>
                  <div style={{ position: 'relative', fontSize: '1.5vw', textAlign: 'start' }}>
                    {t('funds_page.cards.card1.description')}
                  </div>
                  <div className='flex-start flex-row' style={{ position: 'relative', fontSize: '1vw', opacity: 1, textAlign: 'start', color: 'var(--ytd-background-green-color)', opacity: 1, fontWeight: 600 }}>
                    <FontAwesomeIcon icon={faMagnifyingGlassChart} style={{ marginRight: '0.5vw' }} />
                    {t('funds_page.cards.card1.hint')}
                  </div>
                </div>

                <div className={props.scrollPosition > 59 ? 'flex-start flex-column gap-1 descriptionContainer2 show' : 'flex-start flex-column gap-1 descriptionContainer2'} style={{ width: '25vw', position: 'absolute', backgroundColor: '#252729', left: '52.5vw', top: '59.7vw', color: 'white', padding: '1vw', borderRadius: '1.5vw', alignItems: 'start', fontWeight: 300 }}>
                  <div className=' flex-row gap-1' style={{ position: 'relative', fontSize: '1vw', zIndex: 2, width: '100%', display: 'flex', justifyContent: 'space-between' }}>
                    <div style={{ position: 'relative', height: '1vw', width: '1vw', backgroundColor: 'white', borderRadius: '50%', boxShadow: '0px 0px 15px 1px white' }}></div>
                  </div>
                  <div style={{ position: 'relative', fontSize: '1.5vw', textAlign: 'start', padding: '0vw' }}>
                    {t('funds_page.cards.card2.description')}
                  </div>
                  <div className='flex-start flex-row' style={{ position: 'relative', fontSize: '1vw', opacity: 1, textAlign: 'start', color: 'var(--ytd-background-green-color)', opacity: 1, fontWeight: 600 }}>
                    <FontAwesomeIcon icon={faPeopleGroup} style={{ marginRight: '0.5vw' }} />
                    {t('funds_page.cards.card2.hint')}
                  </div>
                </div>

                <div className={props.scrollPosition > 61 ? 'flex-start flex-column gap-1 descriptionContainer3 show' : 'flex-start flex-column gap-1 descriptionContainer3'} style={{ width: '25vw', position: 'absolute', backgroundColor: '#252729', left: '20.5vw', top: '74.5vw', color: 'white', padding: '1vw', borderRadius: '1.5vw', alignItems: 'start', fontWeight: 300 }}>
                  <div className='flex-start flex-row gap-1' style={{ position: 'relative', fontSize: '1vw', zIndex: 2 }}>
                    <div style={{ position: 'relative', height: '1vw', width: '1vw', backgroundColor: 'white', borderRadius: '50%', boxShadow: '0px 0px 15px 1px white' }}></div>
                  </div>
                  <div style={{ position: 'relative', fontSize: '1.5vw', textAlign: 'start' }}>
                    {t('funds_page.cards.card3.description')}
                  </div>
                  <div className='flex-start flex-row' style={{ position: 'relative', fontSize: '1vw', opacity: 1, textAlign: 'start', color: 'var(--ytd-background-green-color)', opacity: 1, fontWeight: 600 }}>
                    <FontAwesomeIcon icon={faEye} style={{ marginRight: '0.5vw' }} />
                    {t('funds_page.cards.card3.hint')}
                  </div>
                </div>

                <div className={props.scrollPosition > 66 ? 'flex-start flex-column gap-1 descriptionContainer4 show' : 'flex-start flex-column gap-1 descriptionContainer4'} style={{ position: 'absolute', backgroundColor: '#252729', left: '32.7vw', top: '97.15vw', color: 'white', padding: '1vw', borderRadius: '1.5vw', alignItems: 'start', fontWeight: 300 }}>
                  <div className='flex-start flex-row gap-1' style={{ position: 'relative', fontSize: '1vw', zIndex: 2 }}>
                    <div style={{ position: 'relative', height: '1vw', width: '1vw', backgroundColor: 'white', borderRadius: '50%', boxShadow: '0px 0px 15px 1px white' }}></div>
                  </div>
                  <div style={{ position: 'relative', fontSize: '1.5vw', textAlign: 'start' }}>
                    {t('funds_page.cards.card4.description')}
                  </div>
                  <div className='flex-start flex-row' style={{ position: 'relative', fontSize: '1vw', opacity: 1, textAlign: 'start', color: 'var(--ytd-background-green-color)', opacity: 1, fontWeight: 600 }}>
                    <FontAwesomeIcon icon={faCube} style={{ marginRight: '0.5vw' }} />
                    {t('funds_page.cards.card4.hint')}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div className='flex-start flex-column gap-1' style={{ position: 'relative', width: '100%', marginTop: '8vw', gap: '2vw' }}>
        <div className={props.scrollPosition > 71 ? 'area show' : 'area'} style={{ fontSize: '3vw', fontWeight: 600, maxWidth: '50vw' }}>
          {t('empowerment_page.title')}
        </div>
        <div className={props.scrollPosition > 71 ? 'area show' : 'area'} style={{ fontSize: '1.5vw', maxWidth: '50vw' }}>
          {t('empowerment_page.description')}</div>
        <div style={{ width: '80%', display: 'flex', justifyContent: 'space-between', alignItems: 'center', position: 'relative', marginTop: '4vw' }}>
          <div className={props.scrollPosition > 75 ? 'box-changeable show' : 'box-changeable'} style={{ width: '18vw', transition: 'opacity 0.5s ease 0.5s, filter 0.5s ease 0s', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', flexDirection: 'column', backgroundImage: 'url(/background/background-box.svg)', height: '20vw', borderBottomLeftRadius: '2vw', borderBottomRightRadius: '2vw', overflow: 'hidden' }}>
            <div style={{ position: 'absolute', top: 0, right: 0, backgroundColor: '#ececec', padding: '1vw', borderRadius: '50%', height: '1vw', width: '1vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><FontAwesomeIcon icon={faPaperclip} style={{ fontWeight: 500, fontSize: '1vw' }} />
            </div>
            <div style={{ fontSize: '1.5vw', width: '80%', textAlign: 'start', fontWeight: 600}}>
              <div style={{maxWidth:'10vw'}}>{t('empowerment_page.cards.card1.title')}</div>
            </div>
            <div style={{ fontSize: '1.2vw', width: '80%', textAlign: 'start', marginTop: '1vw' }}>
              {t('empowerment_page.cards.card1.description')}</div>
            <div style={{ position: 'absolute', bottom: '1.2vw', left: '1.4vw', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.5vw', fontSize: '1vw', textAlign: 'start' }}>
              <FontAwesomeIcon style={{ position: 'relative', color: 'white', backgroundColor: 'black', padding: '0.2vw', width: '0.7vw', height: '0.7vw', borderRadius: '50%' }} icon={faPlus} />
              {t('empowerment_page.cards.card1.hint')}</div>
          </div>

          <div className={props.scrollPosition > 75 ? 'box-changeable show' : 'box-changeable'} style={{ width: '18vw', transition: 'opacity 0.5s ease 0.7s, filter 0.5s ease 0s', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', flexDirection: 'column', backgroundImage: 'url(/background/background-box.svg)', height: '20vw', borderBottomLeftRadius: '2vw', borderBottomRightRadius: '2vw', overflow: 'hidden' }}>
            <div style={{ position: 'absolute', top: 0, right: 0, backgroundColor: '#ececec', padding: '1vw', borderRadius: '50%', height: '1vw', width: '1vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><FontAwesomeIcon icon={faPaperclip} style={{ fontWeight: 500, fontSize: '1vw' }} />
            </div>
            <div style={{ fontSize: '1.5vw', width: '80%', textAlign: 'start', fontWeight: 600 }}>
              <div style={{maxWidth:'10vw'}}>{t('empowerment_page.cards.card2.title')}</div>
              </div>
            <div style={{ fontSize: '1.2vw', width: '80%', textAlign: 'start', marginTop: '1vw' }}>
              {t('empowerment_page.cards.card2.description')}</div>
            <div style={{ position: 'absolute', bottom: '1.2vw', left: '1.4vw', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.5vw', fontSize: '1vw', textAlign: 'start' }}>
              <FontAwesomeIcon style={{ position: 'relative', color: 'white', backgroundColor: 'black', padding: '0.2vw', width: '0.7vw', height: '0.7vw', borderRadius: '50%' }} icon={faPlus} />
              {t('empowerment_page.cards.card2.hint')}</div>
          </div>

          <div className={props.scrollPosition > 75 ? 'box-changeable show' : 'box-changeable'} style={{ width: '18vw', transition: 'opacity 0.5s ease 0.9s, filter 0.5s ease 0s', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', flexDirection: 'column', backgroundImage: 'url(/background/background-box.svg)', height: '20vw', borderBottomLeftRadius: '2vw', borderBottomRightRadius: '2vw', overflow: 'hidden' }}>
            <div style={{ position: 'absolute', top: 0, right: 0, backgroundColor: '#ececec', padding: '1vw', borderRadius: '50%', height: '1vw', width: '1vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><FontAwesomeIcon icon={faPaperclip} style={{ fontWeight: 500, fontSize: '1vw' }} />
            </div>
            <div style={{ fontSize: '1.5vw', width: '80%', textAlign: 'start', fontWeight: 600 }}>
              <div style={{maxWidth:'10vw'}}>{t('empowerment_page.cards.card3.title')}</div>
              </div>
            <div style={{ fontSize: '1.2vw', width: '80%', textAlign: 'start', marginTop: '1vw' }}>
              {t('empowerment_page.cards.card3.description')}</div>
            <div style={{ position: 'absolute', bottom: '1.2vw', left: '1.4vw', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.5vw', fontSize: '1vw', textAlign: 'start' }}>
              <FontAwesomeIcon style={{ position: 'relative', color: 'white', backgroundColor: 'black', padding: '0.2vw', width: '0.7vw', height: '0.7vw', borderRadius: '50%' }} icon={faPlus} />

              {t('empowerment_page.cards.card3.hint')}</div>
          </div>

          <div className={props.scrollPosition > 75 ? 'box-changeable show' : 'box-changeable'} style={{ width: '18vw', transition: 'opacity 0.5s ease 1.1s, filter 0.5s ease 0s', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', flexDirection: 'column', backgroundImage: 'url(/background/background-box.svg)', height: '20vw', borderBottomLeftRadius: '2vw', borderBottomRightRadius: '2vw', overflow: 'hidden' }}>
            <div style={{ position: 'absolute', top: 0, right: 0, backgroundColor: '#ececec', padding: '1vw', borderRadius: '50%', height: '1vw', width: '1vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}><FontAwesomeIcon icon={faPaperclip} style={{ fontWeight: 500, fontSize: '1vw' }} />
            </div>
            <div style={{ fontSize: '1.5vw', width: '80%', textAlign: 'start', fontWeight: 600 }}>
            <div style={{maxWidth:'10vw'}}>{t('empowerment_page.cards.card4.title')}</div>
            </div>
            <div style={{ fontSize: '1.2vw', width: '80%', textAlign: 'start', marginTop: '1vw' }}>
              {t('empowerment_page.cards.card4.description')}
            </div>
            <div style={{ position: 'absolute', bottom: '1.2vw', left: '1.4vw', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.5vw', fontSize: '1vw', textAlign: 'start' }}>
              <FontAwesomeIcon style={{ position: 'relative', color: 'white', backgroundColor: 'black', padding: '0.2vw', width: '0.7vw', height: '0.7vw', borderRadius: '50%' }} icon={faPlus} />
              {t('empowerment_page.cards.card4.hint')}
            </div>
          </div>
        </div>
      </div>
      

      <div className={props.scrollPosition > 78 ? 'area show' : 'area'} style={{ position: 'relative', width: '100%', marginTop: '2vw', gap: '2vw', display:'flex', justifyContent:'center', alignItems:'center' }}>
        <div className='contactBtn' onClick={()=>{window.open("https://www.positiveintelligence.com/saboteurs/ ")}} style={{position:'relative', display:'flex', justifyContent:'center', alignItems:'center', borderRadius:'3vw', fontSize:'1.2vw', fontWeight:600, cursor:'pointer'}}>{t('empowerment_page.sabotage_test')}</div>
      </div>

      <div className='flex-start flex-column gap-1' style={{ position: 'relative', width: '100%', marginTop: '6vw', gap: '2vw' }}>
        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'start', flexDirection: 'column', width: '80%', gap: '1vw' }}>
          <div className={props.scrollPosition > 83 ? 'area show' : 'area'} style={{ fontSize: '3vw', fontWeight: 600, maxWidth: '50vw', textAlign: 'start' }}>              {t('trainings_page.title')}</div>
          <div className={props.scrollPosition > 84 ? 'area show' : 'area'} style={{ fontSize: '1.5vw', maxWidth: '50vw', textAlign: 'start' }}>{t('trainings_page.description')}</div>
        </div>
        <div className={props.scrollPosition > 85 ? 'area show' : 'area'} style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '2vw' }}>
          <Marquee gradientColor="var(--ytd-background-white-color)" autoFill speed={20} gradient style={{ borderRadius: '2vw', position: 'relative', width: '80%' }}>
            <div className='pulsate' style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '6vw', width: '9vw', padding: '1vw', borderRadius: '1.5vw' }}>{t('trainings_page.cards_section1.card1.title')}</div>
            <div className='pulsate' style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '6vw', width: '9vw', padding: '1vw', borderRadius: '1.5vw' }}>{t('trainings_page.cards_section1.card2.title')}</div>
            <div className='pulsate' style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '6vw', width: '9vw', padding: '1vw', borderRadius: '1.5vw' }}>{t('trainings_page.cards_section1.card3.title')}</div>
            <div className='pulsate' style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '6vw', width: '9vw', padding: '1vw', borderRadius: '1.5vw' }}>{t('trainings_page.cards_section1.card4.title')}</div>
            <div className='pulsate' style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '6vw', width: '9vw', padding: '1vw', borderRadius: '1.5vw' }}>{t('trainings_page.cards_section1.card5.title')}</div>
            <div className='pulsate' style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '6vw', width: '9vw', padding: '1vw', borderRadius: '1.5vw' }}>{t('trainings_page.cards_section1.card6.title')}</div>
          </Marquee>

          <Marquee gradientColor="var(--ytd-background-white-color)" autoFill direction='right' speed={40} gradient style={{ borderRadius: '2vw', position: 'relative', width: '80%', marginTop: '-1vw' }}>
            <div className='pulsate' style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '6vw', width: '9vw', padding: '1vw', borderRadius: '1.5vw' }}>{t('trainings_page.cards_section2.card1.title')}</div>
            <div className='pulsate' style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '6vw', width: '9vw', padding: '1vw', borderRadius: '1.5vw' }}>{t('trainings_page.cards_section2.card2.title')}</div>
            <div className='pulsate' style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '6vw', width: '9vw', padding: '1vw', borderRadius: '1.5vw' }}>{t('trainings_page.cards_section2.card3.title')}</div>
            <div className='pulsate' style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '6vw', width: '9vw', padding: '1vw', borderRadius: '1.5vw' }}>{t('trainings_page.cards_section2.card4.title')}</div>
            <div className='pulsate' style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '6vw', width: '9vw', padding: '1vw', borderRadius: '1.5vw' }}>{t('trainings_page.cards_section2.card5.title')}</div>
            <div className='pulsate' style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '6vw', width: '9vw', padding: '1vw', borderRadius: '1.5vw' }}>{t('trainings_page.cards_section2.card6.title')}</div>
            <div className='pulsate' style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '6vw', width: '9vw', padding: '1vw', borderRadius: '1.5vw' }}>{t('trainings_page.cards_section2.card7.title')}</div>
          </Marquee>

          <Marquee gradientColor="var(--ytd-background-white-color)" autoFill speed={60} gradient style={{ borderRadius: '2vw', position: 'relative', width: '80%', marginTop: '-1vw' }}>
            <div className='pulsate' style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '6vw', width: '9vw', padding: '1vw', borderRadius: '1.5vw' }}>{t('trainings_page.cards_section3.card1.title')}</div>
            <div className='pulsate' style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '6vw', width: '9vw', padding: '1vw', borderRadius: '1.5vw' }}>{t('trainings_page.cards_section3.card2.title')}</div>
            <div className='pulsate' style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '6vw', width: '9vw', padding: '1vw', borderRadius: '1.5vw' }}>{t('trainings_page.cards_section3.card3.title')}</div>
            <div className='pulsate' style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '6vw', width: '9vw', padding: '1vw', borderRadius: '1.5vw' }}>{t('trainings_page.cards_section3.card4.title')}</div>
            <div className='pulsate' style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '6vw', width: '9vw', padding: '1vw', borderRadius: '1.5vw' }}>{t('trainings_page.cards_section3.card5.title')}</div>
            <div className='pulsate' style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '6vw', width: '9vw', padding: '1vw', borderRadius: '1.5vw' }}>{t('trainings_page.cards_section3.card6.title')}</div>
          </Marquee>
        </div>
      </div>

      <div className='flex-start flex-row gap-1' style={{ position: 'relative', width: '85%', paddingLeft: '1vw', paddingRight: '1vw', marginTop: '8vw', gap: '0vw', borderTopLeftRadius: '3vw', borderTopRightRadius: '3vw', display: 'flex', justifyContent: 'start', alignItems: 'center', flexDirection: 'column' }}>
        <div style={{ fontSize: '2.5vw', fontWeight: 600, padding: '1vw', paddingLeft: '6vw', paddingRight: '6vw', borderRadius: '2vw', marginTop: '-2.5vw' }}>{t('meeting_page.title')}</div>
        <div style={{ fontSize: '1.5vw', maxWidth: '50vw', textAlign: 'center' }}>
          {t('meeting_page.description')}
        </div>
        <InlineWidget url="https://calendly.com/enevali2016/30min" styles={{ position: 'relative', width: '100%', height: '40vw' }} />
      </div>

      <div className='flex-start flex-row gap-1' style={{ position: 'relative', width: '50%', overflow: 'hidden', paddingLeft: '1vw', paddingRight: '1vw', marginTop: '-2vw', paddingTop: '2vw', paddingBottom: '2vw', backgroundColor: 'rgba(0,0,0,0.9)', flexDirection: 'row', borderTopLeftRadius: '3vw', borderTopRightRadius: '3vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src='background/2.png' style={{ opacity: 1, left: '0vw', position: 'absolute', top: '-30vw', width:'40vw', left:'5vw' }} />

        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%' }}>
          <div style={{ position: 'relative', display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}>
            <img
              src="logo.png"
              style={{
                maxWidth: '3vw',
                position: 'relative',
                transform: 'scale(2)',
                filter: 'invert(1)'
              }}
            />
            <div
              style={{
                marginRight: '0rem',
                fontWeight: 600,
                cursor: 'pointer',
                zIndex: 1,
                position: 'relative',
                left: '1vw',
                fontSize: '1.5vw',
                paddingRight: '1vw',
                color: 'white'
              }}
            >
              YTD Real Consulting
            </div>
          </div>
          <div style={{ color: 'white', width: '100%', textAlign: 'center', marginTop: '0vw', opacity: 1, fontSize: '0.7vw', marginLeft:'-4vw', fontWeight:600 }}>All rights reserved</div>
        </div>
        {/* <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', width: '100%' }}>

          <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', width: '100%', flexDirection: 'column', gap: '0.8vw' }}>
            <div style={{ position: 'relative', display: 'flex', justifyContent: 'start', alignItems: 'center', color: 'white', width: '100%', fontSize: '1.1vw' }}>
              <span className='footerBtn'>
                {t('footer_navbar.option1')}
              </span>
            </div>
            <div style={{ position: 'relative', display: 'flex', justifyContent: 'start', alignItems: 'center', color: 'white', width: '100%', fontSize: '1.1vw' }}>
              <span className='footerBtn'>
                {t('footer_navbar.option2')}</span>

            </div>
            <div style={{ position: 'relative', display: 'flex', justifyContent: 'start', alignItems: 'center', color: 'white', width: '100%', fontSize: '1.1vw' }}>
              <span className='footerBtn'>
                {t('footer_navbar.option3')}</span>

            </div>
            <div style={{ position: 'relative', display: 'flex', justifyContent: 'start', alignItems: 'center', color: 'white', width: '100%', fontSize: '1.1vw' }}>
              <span className='footerBtn'>
                {t('footer_navbar.option4')}</span>

            </div>
          </div>

          <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', color: 'white', width: '100%', flexDirection: 'column', gap: '0.5vw', paddingRight: '4vw' }}>
            <div style={{ position: 'relative', display: 'flex', justifyContent: 'end', alignItems: 'center', color: 'white', width: '100%', fontSize: '1.1vw', opacity: 0.5 }}>
              (+40)-757-283-940
            </div>

            <div style={{ position: 'relative', display: 'flex', justifyContent: 'end', alignItems: 'center', color: 'white', width: '100%', fontSize: '1.1vw', opacity: 0.5 }}>
              contact@ytd.ro
            </div>
            <div style={{ position: 'relative', display: 'flex', justifyContent: 'end', alignItems: 'center', color: 'white', width: '100%', fontSize: '1.1vw', gap: '1vw' }}>
              <FontAwesomeIcon icon={faLinkedin} style={{ cursor: 'pointer' }} />
              <FontAwesomeIcon icon={faInstagram} style={{ opacity: 0.5 }} />
              <FontAwesomeIcon icon={faFacebook} style={{ opacity: 0.5 }} />
            </div>
          </div>
        </div> */}
      </div>

    </div >
  </>

  );
}

export default NewHome;
