import logo from './logo.svg';
import './App.css';
import './styles/home.css';
import Marquee from "react-fast-marquee";
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

function Navbar(props) {
    const navigate = useNavigate();
    const [show, setShow] = useState(true);
    const [lastScrollY, setLastScrollY] = useState(0);

    const controlNavbar = () => {
        // console.log("ok")
        if (window.scrollY > lastScrollY) {
            setShow(false);
        } else {
            setShow(true);
        }
        setLastScrollY(window.scrollY);
    };

    useEffect(() => {
        window.addEventListener('scroll', controlNavbar);

        return () => {
            window.removeEventListener('scroll', controlNavbar);
        };
    }, [lastScrollY]);

    const { t, i18n } = useTranslation();

    const [language, setLanguage] = useState(i18n.language ? i18n.language : "en");

    const changeLanguage = (lng) => {
        i18n.changeLanguage(lng);
        setLanguage(lng);
        localStorage.setItem('i18nLang', lng);
    };



    const scrollToBottom = (percentage) => {
        const totalHeight = document.documentElement.scrollHeight - window.innerHeight; // Total scrollable height
        const targetScrollY = (percentage / 100) * totalHeight; // Calculate the target scroll position based on the percentage

        window.scrollTo({
            top: targetScrollY,
            behavior: 'smooth'
        });
    };


    return (
        <>
            <div
                className={`${show ? 'flex-center flex-row navbar show' : 'flex-center flex-row navbar'}`}
                style={{ position: 'fixed', justifyContent: 'space-between', width: '93%', zIndex: 999 }}
            >
                <div
                    className="flex-center flex-row"
                    style={{
                        left: '0.2vw',
                        padding: '1vw',
                        right: '1vw',
                        marginTop: '1vw',
                        borderRadius: '3vw',
                        transition: 'all ease-in-out 0.3s',
                    }}
                    onClick={()=>{navigate('/')}}
                >
                    <img
                        src="logo.png"
                        style={{
                            maxWidth: '3vw',
                            position: 'relative',
                            transform: 'scale(2)',
                            filter: 'invert(0)',
                        }}
                    />
                    <div
                        style={{
                            marginRight: '0rem',
                            fontWeight: 600,
                            cursor: 'pointer',
                            zIndex: 1,
                            position: 'relative',
                            left: '1vw',
                            fontSize: '1.5vw',
                            paddingRight: '1vw',
                            color: 'black',
                        }}
                    >
                        YTD Real Consulting
                    </div>
                </div>

                <div
                    className="flex-center flex-row"
                    style={{
                        padding: '0.5vw',
                        paddingLeft: '0.5vw',
                        paddingRight: '0.5vw',
                        right: '0.8vw',
                        marginTop: '1vw',
                        gap:'1vw',
                        borderRadius: '3vw',
                        transition: 'all ease-in-out 0.3s',
                    }}
                >
                    {/* {['home', 'news', 'team'].map((item, idx) => (
                        <div
                            key={idx}
                            className="fs-paragraph"
                            style={{
                                marginRight: '0rem',
                                fontWeight: 500,
                                cursor: 'pointer',
                                zIndex: 1,
                                color: 'black',
                            }}
                        >
                            {t(`navbar.${item}`)}
                        </div>
                    ))} */}

                    <div
                        className="fs-paragraph book-meeting"
                        onClick={() => { navigate('/meet') }}
                        style={{
                            fontWeight: 600,
                            cursor: 'pointer',
                            zIndex: 1,
                            transition: 'all ease-in-out 0.3s',
                            color: 'white',
                            backgroundColor: 'black',
                        }}
                    >
                        {t('navbar.schedule_meeting')}
                    </div>


                    <div
                        className="fs-paragraph btn-language flex-center"
                        style={{
                            fontWeight: 600,
                            cursor: 'pointer',
                            zIndex: 1,
                            overflow: 'hidden',
                            width: '0.5vw',
                            height: '0.5vw',
                        }}
                    >
                        <img
                            src="flags/1.svg"
                            onClick={() => changeLanguage('ro')}
                            className="interactable"
                            style={{ position: 'absolute', userSelect: 'none', height: '100%' }}
                        />
                    </div>


                    <div
                        className="fs-paragraph btn-language flex-center"
                        style={{
                            fontWeight: 600,
                            cursor: 'pointer',
                            zIndex: 1,
                            overflow: 'hidden',
                            width: '0.5vw',
                            height: '0.5vw',
                        }}
                    >
                        <img
                            src="flags/2.svg"
                            onClick={() => changeLanguage('es')}
                            className="interactable"
                            style={{ position: 'absolute', userSelect: 'none', height: '100%' }}
                        />
                    </div>



                    <div
                        className="fs-paragraph btn-language flex-center"
                        style={{
                            fontWeight: 600,
                            cursor: 'pointer',
                            zIndex: 1,
                            overflow: 'hidden',
                            width: '0.5vw',
                            height: '0.5vw',
                        }}
                    >
                        <img
                            src="flags/3.webp"
                            onClick={() => changeLanguage('en')}
                            className="interactable"
                            style={{ position: 'absolute', userSelect: 'none', height: '100%' }}
                        />
                    </div>

                    {/*



                    <div
                        className="fs-paragraph btn-language flex-center"
                        style={{
                            marginRight: '0rem',
                            fontWeight: 600,
                            cursor: 'pointer',
                            zIndex: 1,
                            overflow: 'hidden',
                            width: '0.5vw',
                            height: '0.5vw',
                        }}
                    >
                        {language === 'ro' ? (
                            <img
                                src="flags/1.svg"
                                onClick={() => changeLanguage('es')}
                                className="interactable"
                                style={{ position: 'absolute', userSelect: 'none', height: '100%' }}
                            />
                        ) : language === 'es' ? (
                            <img
                                src="flags/2.svg"
                                onClick={() => changeLanguage('en')}
                                className="interactable"
                                style={{ position: 'absolute', userSelect: 'none', height: '100%' }}
                            />
                        ) : language === 'en' ? (
                            <img
                                onClick={() => changeLanguage('ro')}
                                className="interactable"
                                src="flags/3.webp"
                                style={{ position: 'absolute', userSelect: 'none', height: '100%' }}
                            />
                        ) : null}
                    </div>
                    
                    */}
                </div>
            </div>

        </>
    );
}

export default Navbar;
