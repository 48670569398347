// DotsInSphere.js
import React, { useRef } from 'react';
import * as THREE from 'three';
import './index.css'
import { useFrame } from '@react-three/fiber';
import { Html, OrbitControls } from '@react-three/drei';
import { useTranslation } from 'react-i18next';

const DotsInSphere = React.memo(({ numDots = 700, radius = 1.5, phone, ...props }) => {
  if (phone)
    numDots = 500
  if (phone)
    radius = 0.9
  const positions = [];
  const specialPositions = [];

  // Randomly select indices for the special blue points
  const specialIndices = new Set();
  while (specialIndices.size < 20) {
    specialIndices.add(Math.floor(Math.random() * numDots));
  }

  function getDistance(point1, point2) {
    return Math.sqrt(
      (point1[0] - point2[0]) ** 2 +
      (point1[1] - point2[1]) ** 2 +
      (point1[2] - point2[2]) ** 2
    );
  }

  // Generate positions for regular and special points
  const minDistance = radius * 0.1; // Define a minimum distance between special points
  for (let i = 0; i < numDots; i++) {
    const theta = Math.acos(2 * Math.random() - 1); // Random angle from 0 to π
    const phi = Math.random() * 2 * Math.PI; // Random angle from 0 to 2π

    // Convert spherical coordinates to Cartesian coordinates
    const x = radius * Math.sin(theta) * Math.cos(phi);
    const y = radius * Math.sin(theta) * Math.sin(phi);
    const z = radius * Math.cos(theta);

    if (specialIndices.has(i)) {
      let newSpecialPoint = [x, y, z];
      let tooClose = false;

      // Check distance against existing special points
      for (let j = 0; j < specialPositions.length; j++) {
        if (getDistance(newSpecialPoint, specialPositions[j]) < minDistance) {
          tooClose = true;
          break;
        }
      }

      // Regenerate if the point is too close to another special point
      if (tooClose) {
        i--; // Retry this index
        continue;
      }

      // Store positions for special points
      specialPositions.push(newSpecialPoint);
    } else {
      // Store positions for regular points
      positions.push([x, y, z]);
    }
  }


  const meshRef = useRef();

  // Animate the points
  useFrame((state) => {
    if (meshRef.current) {
      // Rotate slowly around the Y axis
      meshRef.current.rotation.y += 0.001;

      // Get the sine value based on the elapsed time
      const scaleFactor = 1.1 + Math.sin(state.clock.getElapsedTime()) * 0.08;

      // Apply the scale factor to all axes (x, y, z)
      meshRef.current.scale.set(scaleFactor, scaleFactor, scaleFactor);
    }
  });

  const { t } = useTranslation();
  return (
    <group ref={meshRef} {...props}>
      {/* Regular points */}
      {positions.map((pos, index) => (
        <mesh key={`dot-${index}`} position={pos} scale={0.12}>
          <sphereGeometry args={[0.05, 16, 16]} />
          <meshBasicMaterial color="#1e1e1e" transparent={true} opacity={0.8} />
        </mesh>
      ))}

      {/* Special blue points */}
      {specialPositions.map((pos, index) => (<>
        <mesh key={`special-dot-${index}`} position={pos} scale={0.2}>
          <sphereGeometry args={[0.1, 16, 16]} /> {/* Larger size for special points */}
          <meshBasicMaterial color="#ca0f2e" />
        </mesh>
        <Html position={pos} style={{ position: 'relative' }}>
          <div style={{ position: 'relative', cursor: 'default', maxWidth:'15vw', userSelect: 'none', backgroundColor: 'rgba(202, 15, 46,0.8)', borderRadius: phone ? '8vw' : '2vw', padding: phone ? '1vw' : '0.4vw', paddingLeft: phone ? '3vw' : '1vw', paddingRight: phone ? '3vw' : '1vw', color: 'white', fontSize: phone ? '2.3vw' : '0.8vw' }}>
            {
              index == 0 ? (<>{t('home.sphere.1')}</>) :
                index == 1 ? (<>{t('home.sphere.2')}</>) :
                  index == 2 ? (<>{t('home.sphere.3')}</>) :
                    index == 3 ? (<>{t('home.sphere.4')}</>) :
                      index == 4 ? (<>{t('home.sphere.5')}</>) :
                        index == 5 ? (<>{t('home.sphere.6')}</>) :
                          index == 6 ? (<>{t('home.sphere.7')}</>) :
                            index == 7 ? (<>{t('home.sphere.8')}</>) :
                              index == 8 ? (<>{t('trainings_page.cards_section1.card1.title')}</>) :
                                index == 9 ? (<>{t('trainings_page.cards_section1.card2.title')}</>) :
                                  index == 10 ? (<>{t('trainings_page.cards_section1.card3.title')}</>) :
                                    index == 11 ? (<>{t('trainings_page.cards_section1.card4.title')}</>) :
                                      index == 12 ? (<>{t('trainings_page.cards_section2.card1.title')}</>) :
                                        index == 13 ? (<>{t('trainings_page.cards_section2.card2.title')}</>) :
                                          index == 14 ? (<>{t('trainings_page.cards_section2.card3.title')}</>) :
                                            index == 15 ? (<>{t('trainings_page.cards_section2.card4.title')}</>) :
                                              index == 16 ? (<>{t('trainings_page.cards_section3.card1.title')}</>) :
                                                index == 17 ? (<>{t('trainings_page.cards_section3.card2.title')}</>) :
                                                  index == 18 ? (<>{t('trainings_page.cards_section3.card3.title')}</>) :
                                                    index == 19 ? (<>{t('trainings_page.cards_section3.card4.title')}</>) : null
            }
          </div>
        </Html>
      </>
      ))}
    </group>
  );
});



// Optionally, you can provide a custom comparison function to control when the component should re-render
// DotsInSphere.displayName = "DotsInSphere"; // Optional for better debugging

export default DotsInSphere;
