/*
Auto-generated by: https://github.com/pmndrs/gltfjsx
*/

import React, { useEffect, useRef } from 'react'
import { useGLTF, PerspectiveCamera } from '@react-three/drei'
import { MeshStandardMaterial } from 'three'
import { useFrame, useThree } from '@react-three/fiber'
import { animated, useSpring } from '@react-spring/three';

export function Pig(props) {

    const { gl } = useThree();

    useEffect(() => {
      gl.domElement.style.pointerEvents = 'auto';
    }, [gl]);

    const { nodes, materials } = useGLTF('/objects/pig.glb')

    const silver = new MeshStandardMaterial({
        map: materials['gold main.006'].map,
        metalness: 1,
        roughness: 0.5
    })

    const gold = new MeshStandardMaterial({
        map: materials['gold main.007'].map,
        metalness: 1,
        roughness: 0.5
    })

    const [pigscale, setPigScale] = useSpring(() => ({
        scale: [1, 1, 1], // Initial scale
        config: { tension: 300, friction: 10 }, // Configure spring
    }));

    const [goldscale, setGoldScale] = useSpring(() => ({
        scale: [2.2, 2.2, 2.2],
        config: { tension: 300, friction: 10 }, // Configure spring
    }));

    const [silverscale, setSilverScale] = useSpring(() => ({
        scale: [2.2, 2.2, 2.2],
        config: { tension: 300, friction: 10 }, // Configure spring
    }));

    const [silverscale2, setSilverScale2] = useSpring(() => ({
        scale: [2.2, 2.2, 2.2],
        config: { tension: 300, friction: 10 }, // Configure spring
    }));

    const [silverscale3, setSilverScale3] = useSpring(() => ({
        scale: [3, 3, 3],
        config: { tension: 300, friction: 10 }, // Configure spring
    }));

    const [silverscale4, setSilverScale4] = useSpring(() => ({
        scale: [3, 3, 3],
        config: { tension: 300, friction: 10 }, // Configure spring
    }));

    const [silverscale5, setSilverScale5] = useSpring(() => ({
        scale: [3, 3, 3],
        config: { tension: 300, friction: 10 }, // Configure spring
    }));

    useFrame((state, delta) => {
        if (coinGold1.current) {
            coinGold1.current.rotation.y += 0.004;
            coinGold1.current.rotation.x += 0.003;
            coinGold1.current.rotation.z += 0.002;
            coinGold1.current.position.y += Math.sin(state.clock.getElapsedTime()) * 0.00008;
        }

        if (coinSilver1.current) {
            coinSilver1.current.rotation.y += 0.007;
            coinSilver1.current.rotation.x += 0.004;
            coinSilver1.current.rotation.z += 0.003;
            coinSilver1.current.position.y += Math.sin(state.clock.getElapsedTime()) * 0.00004;
        }

        if (coinSilver2.current) {
            coinSilver2.current.rotation.y += 0.006;
            coinSilver2.current.rotation.x += 0.007;
            coinSilver2.current.rotation.z += 0.002;
            coinSilver2.current.position.y += Math.sin(state.clock.getElapsedTime()) * 0.00002;
        }

        if (coinSilver3.current) {
            coinSilver3.current.rotation.y += 0.006;
            coinSilver3.current.rotation.x += 0.007;
            coinSilver3.current.rotation.z += 0.002;
            coinSilver3.current.position.y += Math.sin(state.clock.getElapsedTime()) * 0.00002;
        }

        if (coinSilver4.current) {
            coinSilver4.current.rotation.y += 0.003;
            coinSilver4.current.rotation.x += 0.01;
            coinSilver4.current.rotation.z += 0.004;
            coinSilver4.current.position.y += Math.sin(state.clock.getElapsedTime()) * 0.00002;
        }

        if (coinSilver5.current) {
            coinSilver5.current.rotation.y += 0.0015;
            coinSilver5.current.rotation.x += 0.002;
            coinSilver5.current.rotation.z += 0.003;
            coinSilver5.current.position.y += Math.sin(state.clock.getElapsedTime()) * 0.00002;
        }

        if (pig.current) {
            pig.current.position.y += Math.sin(state.clock.getElapsedTime()) * 0.00005;
            pig.current.rotation.y += Math.sin(state.clock.getElapsedTime()) * 0.00005;
        }
    })

    const pig = useRef();
    const coinGold1 = useRef();
    const coinSilver1 = useRef();
    const coinSilver2 = useRef();
    const coinSilver3 = useRef();
    const coinSilver4 = useRef();
    const coinSilver5 = useRef();

    return (
        <group {...props} dispose={null}>
            <animated.mesh
                ref={pig}
                castShadow
                receiveShadow
                geometry={nodes.Cube.geometry}
                material={materials['Pig_White Marble Texture']}
                position={[0, -0.02, 0]}
                rotation={[0.1, 0.7, 0.2]}
                scale={pigscale.scale} // Use the animated scale
                onPointerEnter={() => setPigScale({ scale: [1.1, 1.1, 1.1] })} // Scale up on hover
                onPointerLeave={() => setPigScale({ scale: [1, 1, 1] })} // Scale back to normal
            >
                <mesh
                    castShadow
                    receiveShadow
                    geometry={nodes.Bolt001.geometry}
                    material={materials['Pig_White Marble Texture']}
                    position={[0.002, 0.132, -0.068]}
                    rotation={[-0.008, 0, 0]}
                    scale={0.005}
                />
            </animated.mesh>

            <animated.group position={[0.05, 0.22, 0]} rotation={[1, 0, -0.4]}
                scale={goldscale.scale} // Use the animated scale
                onPointerEnter={() => setGoldScale({ scale: [2.8, 2.8, 2.8] })} // Scale up on hover
                onPointerLeave={() => setGoldScale({ scale: [2.2, 2.2, 2.2] })} // Scale back to normal
            >
                <pointLight color={"white"} intensity={0.1} position={[0, -0.0, 0.015]} />
                <mesh
                    ref={coinGold1}
                    castShadow
                    receiveShadow
                    geometry={nodes.Circle001.geometry}
                    material={gold}
                />
            </animated.group>

            <animated.group position={[-0.05, 0.3, 0]} rotation={[1.2, 0, 0.3]}
                scale={silverscale.scale} // Use the animated scale
                onPointerEnter={() => setSilverScale({ scale: [2.8, 2.8, 2.8] })} // Scale up on hover
                onPointerLeave={() => setSilverScale({ scale: [2.2, 2.2, 2.2] })} // Scale back to normal
            >
                <pointLight color={"white"} intensity={0.5} distance={1} position={[0, 0.03, 0.01]} />
                <mesh
                    ref={coinSilver1}
                    castShadow
                    receiveShadow
                    geometry={nodes.Circle001.geometry}
                    material={silver}
                />
            </animated.group>


            <animated.group position={[0.15, 0.3, 0]} rotation={[1.2, 0, 0.3]}
                scale={silverscale2.scale} // Use the animated scale
                onPointerEnter={() => setSilverScale2({ scale: [2.8, 2.8, 2.8] })} // Scale up on hover
                onPointerLeave={() => setSilverScale2({ scale: [2.2, 2.2, 2.2] })} // Scale back to normal
            >
                <pointLight color={"white"} intensity={0.5} distance={1} position={[0, 0.03, 0.01]} />
                <mesh
                    ref={coinSilver2}
                    castShadow
                    receiveShadow
                    geometry={nodes.Circle001.geometry}
                    material={silver}
                />
            </animated.group>

            <animated.group position={[0.32, -0.14, 0]} rotation={[1.2, 0, 0.3]}
                scale={silverscale3.scale} // Use the animated scale
                onPointerEnter={() => setSilverScale3({ scale: [3.3, 3.3, 3.3] })} // Scale up on hover
                onPointerLeave={() => setSilverScale3({ scale: [3, 3, 3] })} // Scale back to normal
            >
                <pointLight color={"white"} intensity={0.5} distance={1} position={[0, 0.03, 0.01]} />
                <mesh
                    ref={coinSilver3}
                    castShadow
                    receiveShadow
                    geometry={nodes.Circle001.geometry}
                    material={silver}
                />
            </animated.group>
            

            <animated.group position={[-0.28, -0.27, 0]} rotation={[1.2, 0, 0.3]}
                scale={silverscale4.scale} // Use the animated scale
                onPointerEnter={() => setSilverScale4({ scale: [3.3, 3.3, 3.3] })} // Scale up on hover
                onPointerLeave={() => setSilverScale4({ scale: [3, 3, 3] })} // Scale back to normal
            >
                <pointLight color={"white"} intensity={0.5} distance={1} position={[0, 0.03, 0.01]} />
                <mesh
                    ref={coinSilver4}
                    castShadow
                    receiveShadow
                    geometry={nodes.Circle001.geometry}
                    material={silver}
                />
            </animated.group>
            
            <animated.group position={[0.12, -0.72, 0]} rotation={[1.2, 0, 0.3]}
                scale={silverscale5.scale} // Use the animated scale
                onPointerEnter={() => setSilverScale5({ scale: [3.3, 3.3, 3.3] })} // Scale up on hover
                onPointerLeave={() => setSilverScale5({ scale: [3, 3, 3] })} // Scale back to normal
            >
                <pointLight color={"white"} intensity={0.5} distance={1} position={[0, 0.03, 0.01]} />
                <mesh
                    ref={coinSilver5}
                    castShadow
                    receiveShadow
                    geometry={nodes.Circle001.geometry}
                    material={silver}
                />
            </animated.group>

        </group>
    )
}

useGLTF.preload('/objects/pig.glb')

