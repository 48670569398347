import logo from './logo.svg';
import './App.css';
import './styles/home.css';
import Marquee from "react-fast-marquee";
import Navbar from './Navbar';
import React, { Suspense, useEffect, useMemo, useRef, useState } from 'react';
import { I18nextProvider, useTranslation } from 'react-i18next';
import { Canvas, useFrame } from '@react-three/fiber';
import * as THREE from 'three';
import { Pig } from './Pig';
import { Environment, OrbitControls, PointMaterial, Points, PresentationControls } from '@react-three/drei';
import DotsInSphere from './DotsInSphere';
import { useSpring, animated } from 'react-spring';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowAltCircleDown, faArrowDown, faCircleInfo, faCube, faEye, faHourglass, faHourglass3, faInfo, faInfoCircle, faMagnifyingGlass, faMagnifyingGlassChart, faPaperclip, faPeopleGroup, faPlus, faQuoteLeft, faUpRightAndDownLeftFromCenter } from '@fortawesome/free-solid-svg-icons';
import { InlineWidget } from 'react-calendly';
import { faFacebook, faInstagram, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import { PigPhone } from './PigPhone';
import { useNavigate } from 'react-router-dom';


const Lights = React.memo(() => {
  // State to store mouse position
  const [mousePos, setMousePos] = useState({ x: 0, y: 0 });

  // Update mouse position on mouse move
  useEffect(() => {
    const handleMouseMove = (event) => {
      const x = (event.clientX / window.innerWidth) * 2 - 1; // Normalize to [-1, 1]
      const y = -(event.clientY / window.innerHeight) * 2 + 1; // Normalize to [-1, 1]
      setMousePos({ x, y });
    };

    window.addEventListener('mousemove', handleMouseMove);

    return () => {
      window.removeEventListener('mousemove', handleMouseMove);
    };
  }, []);

  // Light positions with offsets based on mouse position
  const lightOffset = 0.5; // Adjust this value for the intensity of the offset
  const lights = [
    { intensity: 3, position: [0, 3, 0] },
    { intensity: 3, position: [-5, 0, 3] },
    { intensity: 3, position: [0, -3, 0] },
    { intensity: 10, position: [-2, -1, 0], color: "rgb(202, 15, 46)" },
    { intensity: 3, position: [0, 1, 2], color: "white" },
  ];

  return (
    <>
      {lights.map((light, index) => (
        <pointLight
          key={index}
          color={light.color || "white"}
          intensity={light.intensity}
          position={[
            light.position[0] + mousePos.x * lightOffset,
            light.position[1] + mousePos.y * lightOffset,
            light.position[2],
            light.position[3],
          ]}
        />
      ))}
    </>
  );
});


const ProgressLine = ({ scrollPosition }) => {
  const pathLength = 50;

  const lineStyle = useSpring({
    strokeDashoffset: scrollPosition * 10,
    strokeDasharray: pathLength,
    config: { tension: 100, friction: 20 },
  });

  return (
    <div>
      <svg style={{ width: '240vw', height: 'auto', position: 'absolute', left: '0vw', top: '20vw', pointerEvents: 'none', userSelect: 'none', zIndex: 1 }} width="431" height="650" viewBox="-50 -50 531 650" fill="none" xmlns="http://www.w3.org/2000/svg">
        <animated.path d="M174 895.5C174.667 841.667 174 93.5001 174 38C174 0.500061 139 0.500053 139 0.500053H37.25H0"
          stroke="white"
          strokeWidth="2"
          strokeLinecap="round"
          style={lineStyle}
        />
      </svg>
    </div>
  );
};


function NewHomePhone(props) {
  const styles = useSpring({
    position: 'absolute',
    left: '-50vw',
    top: '70vw',
    width: '150vw',
    opacity: 0.5,
    transform: `rotate(25deg) translateY(${-(Math.max(props.scrollPosition * 0.5, -0.1))}vw)`,
    config: { tension: 170, friction: 26 }, // You can tweak the animation speed with these settings
  });

  //style={{ position: 'absolute', width: '50%', top: '10vw', left: '70vw', transform:'rotate(-90deg)' }}
  const styles2 = useSpring({
    position: 'absolute',
    left: '40vw',
    top: '-50vw',
    opacity: 0.2,
    width: '150vw',
    transform: `rotate(-90deg) translateY(${-(Math.max(props.scrollPosition * 0.3, -0.1))}vw)`,
    config: { tension: 170, friction: 26 }, // You can tweak the animation speed with these settings
  });

  useEffect(() => {
    // console.log("scroll: ", props.scrollPosition)
  }, [props.scrollPosition])
  const { t } = useTranslation();

  const memoizedSphere = useMemo(() =>
    <DotsInSphere position={[0, 1, 0]} phone={true} />
    , [])

  const navigate = useNavigate();

  return (<>
    <div className='flex-center flex-column gap-1' style={{ width: '100%', minHeight: '100vh' }}>
      <div className='flex-center flex-column gap-1' style={{ width: '100%', height: '100vh', position: 'relative' }}>

        <div style={{ position: 'absolute', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%', height: '100%', flexDirection: 'column', overflow: 'hidden', zIndex: 0 }}>
          <Canvas
            gl={{
              antialias: true,
              toneMapping: THREE.NoToneMapping,
              powerPreference: "high-performance",
              alpha: true,
              stencil: false,
            }}
            dpr={[1, 1.5]} // Limits device pixel ratio between 1 and 1.5 for better performance
            camera={{ position: [0, 0, 20], fov: 15 }}
            style={{ position: 'relative', height: '100%', width: '100%', zIndex: 1 }}
          >
            <PresentationControls
              enabled={true} // the controls can be disabled by setting this to false
              global={true} // Spin globally or by dragging the model
              cursor={false} // Whether to toggle cursor style on drag
              snap={false} // Snap-back to center (can also be a spring config)
              speed={1} // Speed factor
              zoom={1} // Zoom factor when half the polar-max is reached
              rotation={[0, 0, 0]} // Default rotation
              // polar={[-Math.PI, Math.PI]} // Vertical limits
              // azimuth={[-Math.PI / 10, Math.PI / 10]} // Horizontal limits
              config={{ mass: 1, tension: 170, friction: 26 }} // Spring config
            >
              <group position={[0,0.1,0]}>
                {memoizedSphere}
              </group>
            </PresentationControls>
          </Canvas>
        </div>
        <div className='flex-center' style={{ fontWeight: 600, zIndex: 2, fontSize: '8.5vw', color: 'black', width: '90%', marginTop: '120vw', textAlign: 'end', display: 'flex', justifyContent: 'end', alignItems: 'center' }}><div style={{ maxWidth: '70vw', textAlign: 'end', position: 'relative' }}>{t('home.title')}</div></div>
        <div className='flex-center flex-column' style={{ fontWeight: 300, zIndex: 2, height: '100%', fontSize: '5vw', color: 'black', width: '90%', padding: '1vw', paddingTop: '5vw', paddingBottom: '8vw', borderBottom: 'solid 2vw #ca0f2e', zIndex: 3, backgroundColor: 'rgba(0,0,0,0.02)', backdropFilter: 'blur(0.5vw)', WebkitBackdropFilter: 'blur(0.5vw)', top: '4vw', borderRadius: '6vw' }}>
          {t('home.description')}
        </div>

        <div className='flex-center' style={{ fontWeight: 600, fontSize: '4vw', width: '100%', top: '-6.5vw', height: '100%', zIndex: 3 }}>
          <div className='contactBtn' onClick={() => { navigate('/contact') }} style={{ position: 'relative', cursor: 'pointer', borderRadius: '6vw' }}>{t('home.contact')}</div>
        </div>

        <div style={{ position: 'absolute', width: '100%', left: '-40vw', bottom: '77vw', fontWeight: 600, opacity: 0.2, fontSize: '4.8vw', transform: 'rotate(90deg)' }}>{t('home.scroll')}</div>
        <div style={{ position: 'absolute', width: '100%', left: '-40.45vw', bottom: '55vw', opacity: 0.2, fontSize: '4.8vw' }}><FontAwesomeIcon icon={faArrowDown} /></div>
      </div>

      <div className='flex-start flex-column gap-1' style={{ position: 'relative', height: '870vw', width: '100%', marginTop: '30vw', top: '0vw' }}>
        <div className='container-1 flex-start flex-column gap-1' style={{ height: '670vw' }}>
          <animated.img style={styles} src='objects/metal1.png' />
          <animated.img style={styles2} src='objects/metal1.png' />
          <div className='flex-center flex-column gap-1' style={{ color: 'white', textAlign: 'center', alignItems: 'center', width: '100%', top: '1rem', position: 'relative' }}>
            <div className='flex-center flex-column gap-1' style={{ color: 'white', textAlign: 'center', alignItems: 'center', width: '100%', position: 'relative' }}>
              <div className={props.scrollPosition > 4 ? 'container1-subtitle show flex-center' : 'container1-subtitle flex-center'} style={{ position: 'relative', fontWeight: 200, textAlign: 'center', marginTop: '6.5vw' }}>
                <div style={{ position: 'relative', textAlign: 'center', fontSize: '4vw', padding: '0.3vw', paddingLeft: '3vw', paddingRight: '3vw', border: 'solid 0.1vw', borderRadius: '8vw', backgroundColor: 'rgba(255,255,255,0.05)' }}>
                  <div style={{ color: 'white' }}>{t('optimization_page.section.header')}</div>
                </div>
              </div>
              <div className={props.scrollPosition > 5 ? 'container1-title show' : 'container1-title'} style={{ position: 'relative', fontSize: '7vw', fontWeight: 600, width: '80%', textAlign: 'center', marginBottom: '7vw' }}>{t('optimization_page.title')}</div>
              <div className='flex-start flex-row' style={{ position: 'relative', fontWeight: 600, top: '0vw', width: '90%', justifyContent: 'center', alignItems: 'start', gap: '4vw', flexDirection: 'column' }}>
                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', width: '100%' }}>
                  {[
                    { key: 1, scrollPos: 12, description: 'optimization_page.cards.card3.description', hint: 'optimization_page.cards.card3.hint' },
                    { key: 2, scrollPos: 16, description: 'optimization_page.cards.card1.description', hint: 'optimization_page.cards.card1.hint' },
                    { key: 3, scrollPos: 20, description: 'optimization_page.cards.card2.description', hint: 'optimization_page.cards.card2.hint' },
                    { key: 4, scrollPos: 24, description: 'optimization_page.cards.card4.description', hint: 'optimization_page.cards.card4.hint' },
                  ].map(({ key, scrollPos, description, hint }) => (
                    <div
                      key={key}
                      className={`flex-start flex-column box-card ${props.scrollPosition > scrollPos
                        ? key === 4 ? 'show4' : `semishow${key}`
                        : props.scrollPosition > scrollPos - 4
                          ? `show${key}`
                          : ''
                        }`}
                      style={{ width: '90%', borderRadius: '8vw', position: 'absolute', padding: '5vw', alignItems: 'start' }}
                    >
                      <div style={{ fontSize: '8vw', textAlign: 'start', opacity: 0.5 }}>
                        <FontAwesomeIcon icon={faQuoteLeft} />
                      </div>
                      <div style={{ fontSize: '5.5vw', textAlign: 'start', marginTop: '0vw' }}>
                        {t(description)}
                      </div>
                      <div
                        className="flex-start flex-row"
                        style={{
                          fontSize: '4vw',
                          textAlign: 'start',
                          borderRadius: '8vw',
                          padding: '0.5rem 1.5rem',
                          color: 'black',
                          marginTop: '0.5rem',
                          gap: '0.5rem',
                          width: '100%'
                        }}
                      >
                        <div
                          style={{
                            position: 'relative',
                            marginLeft: '-6vw',
                            height: '3vw',
                            width: '3vw',
                            borderRadius: '50%',
                            backgroundColor: 'var(--ytd-background-green-color)',
                          }}
                        ></div>
                        {t(hint)}
                      </div>
                    </div>
                  ))}
                </div>

              </div>
              <div className='flex-center flex-row' style={{ position: 'relative', fontWeight: 600, top: '125vw', width: '80%', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '4vw', opacity: 0.5 }}>
                <div className={props.scrollPosition > 24 ? 'area show' : 'area'} style={{ fontSize: '7vw', textAlign: 'center', marginTop: '-3vw', padding: '1vw', borderRadius: '50%' }}><FontAwesomeIcon icon={faCircleInfo} /></div>
                <div className={props.scrollPosition > 25 ? 'area show' : 'area'} style={{ fontSize: '4vw', textAlign: 'center', marginTop: '-1.5vw' }}>
                  {t('optimization_page.section.footer')}
                </div>
                <div className={props.scrollPosition > 26 ? 'area show' : 'area'} style={{ fontSize: '4vw', textAlign: 'center', marginTop: '-1.5vw' }}>
                  {t('optimization_page.section.footer2')}
                </div>
              </div>
            </div>
          </div>
        </div>

        {/* PIGGY SECTION */}
        <div className='flex-start flex-column gap-1' style={{ position: 'absolute', bottom: '0vw', background: '#141416', width: '100%', height: '410vw', borderRadius: '8vw', marginTop: '-20vw', overflow: 'hidden' }}>
          <img src='background/2.png' style={{ opacity: 1, left: '-70vw', position: 'absolute', top: '60vw', height: '100vw', width: '100vw' }} />
          <img src='background/2.png' style={{ opacity: 1, right: '-70vw', position: 'absolute', top: '140vw', height: '100vw', width: '100vw' }} />
          <img src='background/2.png' style={{ opacity: 1, left: '-70vw', position: 'absolute', top: '220vw', height: '100vw', width: '100vw' }} />
          <img src='background/2.png' style={{ opacity: 1, right: '-70vw', position: 'absolute', top: '300vw', height: '100vw', width: '100vw' }} />

          <Canvas
            gl={{
              antialias: true,
              toneMapping: THREE.NoToneMapping,
              powerPreference: "high-performance",
              alpha: true,
              stencil: false,
            }}
            linear
            dpr={[1, 1.5]} // Limits device pixel ratio between 1 and 1.5 for better performance
            camera={{ position: [0, 0, 20], fov: 15 }}
            style={{ position: 'absolute', width: '100%', zIndex: 4, backgroundColor: 'transparent', pointerEvents: 'none' }}
          >
            <Lights />
            <Suspense fallback={null}>
              <PigPhone scale={3} position={[-0.5, 1.3, 0]} rotation={[0, 0.2, 0.3]} />
            </Suspense>
          </Canvas>

          <div className='flex-end flex-column gap-1' style={{ color: 'white', width: '100%', alignItems: 'end', top: '10vw' }}>
            <div className='flex-end flex-column gap-1' style={{ color: 'white', width: '100%' }}>
              <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'end', flexDirection: 'column', width: '95%' }}>
                <div className={props.scrollPosition > 30 ? 'area show flex-end flex-column gap-1' : 'area flex-end flex-column gap-1'} style={{ color: 'white', width: '100%', fontSize: '7vw', textAlign: 'center', fontWeight: 500, zIndex: 4, display: 'inline' }}>{t('funds_page.title')}</div>
                <div className={props.scrollPosition > 32 ? 'descriptionPiggy show flex-end flex-column gap-1' : 'descriptionPiggy flex-end flex-column gap-1'} style={{ width: '65%', fontSize: '4vw', textAlign: 'start', fontWeight: 600, zIndex: 4, marginTop: '5vw', backgroundColor: 'rgba(0,0,0,0.2)', padding: '4vw', borderRadius: '5vw', backdropFilter: 'blur(1vw)', WebkitBackdropFilter: 'blur(1vw)' }}>{t('funds_page.description')}</div>
              </div>

              <div style={{ position: 'absolute', width: '100vw', top: '13vw', left: '-51vw', zIndex: 0 }}>
                <ProgressLine scrollPosition={props.scrollPosition} />
                <svg style={{ width: '240vw', height: 'auto', position: 'absolute', left: '0vw', top: '20vw', pointerEvents: 'none', userSelect: 'none' }} width="431" height="650" viewBox="-50 -50 531 650" fill="none" xmlns="http://www.w3.org/2000/svg">
                  <path d="M174 895.5C174.667 841.667 174 93.5001 174 38C174 0.500061 139 0.500053 139 0.500053H37.25H0"
                    stroke="#252729"
                    strokeWidth="5"
                    strokeLinecap="round"
                  />
                </svg>

              </div>

              <div style={{ position: 'absolute', width: '100%', top: '13vw' }}>
                {/* <div style={{ position: 'absolute', height: '10vw', width: '10vw', borderRadius: '50%', backgroundColor: 'white', top: '40vw', left: '10vw', zIndex: 3 }}></div> */}
                <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', top: '90vw', gap: '15vw' }}>
                  <div className={props.scrollPosition > 37 ? 'flex-start flex-column gap-1 descriptionContainer show' : 'flex-start flex-column gap-1 descriptionContainer'} style={{ position: 'relative', backgroundColor: '#252729', color: 'white', padding: '4vw', borderRadius: '8vw', alignItems: 'start', fontWeight: 300 }}>
                    <div className='flex-center flex-row gap-1' style={{ position: 'relative', fontSize: '3vw', zIndex: 2 }}>
                      <div style={{ position: 'relative', height: '3vw', width: '3vw', backgroundColor: 'white', borderRadius: '50%', boxShadow: '0px 0px 15px 1px white' }}></div>
                    </div>
                    <div style={{ position: 'relative', fontSize: '5vw', textAlign: 'start', width: '100%' }}>
                      {t('funds_page.cards.card1.description')}
                    </div>
                    <div className='flex-start flex-row' style={{ position: 'relative', fontSize: '4vw', opacity: 1, textAlign: 'start', color: 'var(--ytd-background-green-color)', opacity: 1, fontWeight: 600 }}>
                      <FontAwesomeIcon icon={faMagnifyingGlassChart} style={{ marginRight: '2vw' }} />
                      {t('funds_page.cards.card1.hint')}
                    </div>
                  </div>

                  <div className={props.scrollPosition > 40 ? 'flex-start flex-column gap-1 descriptionContainer2 show' : 'flex-start flex-column gap-1 descriptionContainer2'} style={{ position: 'relative', backgroundColor: '#252729', color: 'white', padding: '4vw', borderRadius: '8vw', alignItems: 'start', fontWeight: 300 }}>
                    <div className='flex-start flex-row gap-1' style={{ position: 'relative', fontSize: '3vw', zIndex: 2 }}>
                      <div style={{ position: 'relative', height: '3vw', width: '3vw', backgroundColor: 'white', borderRadius: '50%', boxShadow: '0px 0px 15px 1px white' }}></div>
                    </div>
                    <div style={{ position: 'relative', fontSize: '5vw', textAlign: 'start', width: '100%' }}>

                      {t('funds_page.cards.card2.description')}
                    </div>
                    <div className='flex-start flex-row' style={{ position: 'relative', fontSize: '4vw', opacity: 1, textAlign: 'start', color: 'var(--ytd-background-green-color)', opacity: 1, fontWeight: 600 }}>
                      <FontAwesomeIcon icon={faPeopleGroup} style={{ marginRight: '2vw' }} />
                      {t('funds_page.cards.card2.hint')}
                    </div>
                  </div>

                  <div className={props.scrollPosition > 43 ? 'flex-start flex-column gap-1 descriptionContainer3 show' : 'flex-start flex-column gap-1 descriptionContainer3'} style={{ position: 'relative', backgroundColor: '#252729', color: 'white', padding: '4vw', borderRadius: '8vw', alignItems: 'start', fontWeight: 300 }}>
                    <div className='flex-start flex-row gap-1' style={{ position: 'relative', fontSize: '3vw', zIndex: 2 }}>
                      <div style={{ position: 'relative', height: '3vw', width: '3vw', backgroundColor: 'white', borderRadius: '50%', boxShadow: '0px 0px 15px 1px white' }}></div>
                    </div>
                    <div style={{ position: 'relative', fontSize: '5vw', textAlign: 'start', width: '100%' }}>

                      {t('funds_page.cards.card3.description')}
                    </div>
                    <div className='flex-start flex-row' style={{ position: 'relative', fontSize: '4vw', opacity: 1, textAlign: 'start', color: 'var(--ytd-background-green-color)', opacity: 1, fontWeight: 600 }}>
                      <FontAwesomeIcon icon={faEye} style={{ marginRight: '2vw' }} />
                      {t('funds_page.cards.card3.hint')}
                    </div>
                  </div>

                  <div className={props.scrollPosition > 46 ? 'flex-start flex-column gap-1 descriptionContainer4 show' : 'flex-start flex-column gap-1 descriptionContainer4'} style={{ position: 'relative', backgroundColor: '#252729', color: 'white', padding: '4vw', borderRadius: '8vw', alignItems: 'start', fontWeight: 300 }}>
                    <div className='flex-start flex-row gap-1' style={{ position: 'relative', fontSize: '3vw', zIndex: 2 }}>
                      <div style={{ position: 'relative', height: '3vw', width: '3vw', backgroundColor: 'white', borderRadius: '50%', boxShadow: '0px 0px 15px 1px white' }}></div>
                    </div>
                    <div style={{ position: 'relative', fontSize: '5vw', textAlign: 'start', width: '100%' }}>

                      {t('funds_page.cards.card4.description')}
                    </div>
                    <div className='flex-start flex-row' style={{ position: 'relative', fontSize: '4vw', opacity: 1, textAlign: 'start', color: 'var(--ytd-background-green-color)', opacity: 1, fontWeight: 600 }}>
                      <FontAwesomeIcon icon={faMagnifyingGlassChart} style={{ marginRight: '2vw' }} />
                      {t('funds_page.cards.card4.hint')}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      <div className='flex-start flex-column gap-1' style={{ position: 'relative', width: '100%', marginTop: '25vw', gap: '2vw' }}>
        <div className={props.scrollPosition > 52 ? 'area show' : 'area'} style={{ fontSize: '7vw', fontWeight: 600, width: '80%' }}>
          {t('empowerment_page.title')}
        </div>
        <div className={props.scrollPosition > 54 ? 'area show' : 'area'} style={{ fontSize: '4.5vw', width: '80%', marginTop: '5vw' }}>
          {t('empowerment_page.description')}</div>
        <div style={{ width: '90%', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', marginTop: '10vw', gap: '3vw', flexDirection: 'column' }}>
          <div className={props.scrollPosition > 60 ? 'box-changeable show' : 'box-changeable'} style={{ width: '80%', transition: 'opacity 0.5s ease 0s, filter 0.5s ease 0s', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', flexDirection: 'column', backgroundImage: 'url(/background/background-box-phone.png)', height: '85vw', borderBottomLeftRadius: '2vw', borderBottomRightRadius: '2vw', overflow: 'hidden' }}>
            <div style={{ position: 'absolute', top: 0, right: 0, backgroundColor: '#ececec', padding: '1vw', borderRadius: '50%', height: '14vw', width: '14vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <FontAwesomeIcon icon={faPaperclip} style={{ fontWeight: 500, fontSize: '6vw' }} />
            </div>
            <div style={{ fontSize: '6.5vw', width: '80%', textAlign: 'start', fontWeight: 600, marginTop: '-5vw' }}>
              {t('empowerment_page.cards.card1.title')}
            </div>
            <div style={{ fontSize: '4.5vw', width: '80%', textAlign: 'start', marginTop: '3.5vw' }}>
              {t('empowerment_page.cards.card1.description')}</div>
            <div style={{ position: 'absolute', bottom: '6vw', left: '7vw', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.5vw', fontSize: '4.5vw', textAlign: 'start' }}>
              <FontAwesomeIcon style={{ position: 'relative', color: 'white', backgroundColor: 'black', padding: '1vw', width: '4vw', height: '4vw', borderRadius: '50%', marginRight: '1vw' }} icon={faPlus} />
              {t('empowerment_page.cards.card1.hint')}</div>
          </div>

          <div className={props.scrollPosition > 64 ? 'box-changeable show' : 'box-changeable'} style={{ width: '80%', transition: 'opacity 0.5s ease 0s, filter 0.5s ease 0s', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', flexDirection: 'column', backgroundImage: 'url(/background/background-box-phone.png)', height: '85vw', borderBottomLeftRadius: '2vw', borderBottomRightRadius: '2vw', overflow: 'hidden' }}>
            <div style={{ position: 'absolute', top: 0, right: 0, backgroundColor: '#ececec', padding: '1vw', borderRadius: '50%', height: '14vw', width: '14vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <FontAwesomeIcon icon={faPaperclip} style={{ fontWeight: 500, fontSize: '6vw' }} />
            </div>
            <div style={{ fontSize: '6.5vw', width: '80%', textAlign: 'start', fontWeight: 600, marginTop: '-5vw' }}>
              {t('empowerment_page.cards.card2.title')}</div>
            <div style={{ fontSize: '4.5vw', width: '80%', textAlign: 'start', marginTop: '3.5vw' }}>
              {t('empowerment_page.cards.card2.description')}</div>
            <div style={{ position: 'absolute', bottom: '6vw', left: '7vw', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.5vw', fontSize: '4.5vw', textAlign: 'start' }}>
              <FontAwesomeIcon style={{ position: 'relative', color: 'white', backgroundColor: 'black', padding: '1vw', width: '4vw', height: '4vw', borderRadius: '50%', marginRight: '1vw' }} icon={faPlus} />
              {t('empowerment_page.cards.card2.hint')}
            </div>
          </div>

          <div className={props.scrollPosition > 68 ? 'box-changeable show' : 'box-changeable'} style={{ width: '80%', transition: 'opacity 0.5s ease 0s, filter 0.5s ease 0s', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', flexDirection: 'column', backgroundImage: 'url(/background/background-box-phone.png)', height: '85vw', borderBottomLeftRadius: '2vw', borderBottomRightRadius: '2vw', overflow: 'hidden' }}>
            <div style={{ position: 'absolute', top: 0, right: 0, backgroundColor: '#ececec', padding: '1vw', borderRadius: '50%', height: '14vw', width: '14vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <FontAwesomeIcon icon={faPaperclip} style={{ fontWeight: 500, fontSize: '6vw' }} />
            </div>
            <div style={{ fontSize: '6.5vw', width: '80%', textAlign: 'start', fontWeight: 600, marginTop: '-5vw' }}>
              {t('empowerment_page.cards.card3.title')}</div>
            <div style={{ fontSize: '4.5vw', width: '80%', textAlign: 'start', marginTop: '3.5vw' }}>
              {t('empowerment_page.cards.card3.description')}
            </div>
            <div style={{ position: 'absolute', bottom: '6vw', left: '7vw', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.5vw', fontSize: '4.5vw', textAlign: 'start' }}>
              <FontAwesomeIcon style={{ position: 'relative', color: 'white', backgroundColor: 'black', padding: '1vw', width: '4vw', height: '4vw', borderRadius: '50%', marginRight: '1vw' }} icon={faPlus} />
              {t('empowerment_page.cards.card3.hint')}
            </div>
          </div>

          <div className={props.scrollPosition > 72 ? 'box-changeable show' : 'box-changeable'} style={{ width: '80%', transition: 'opacity 0.5s ease 0s, filter 0.5s ease 0s', display: 'flex', justifyContent: 'center', alignItems: 'center', position: 'relative', flexDirection: 'column', backgroundImage: 'url(/background/background-box-phone.png)', height: '85vw', borderBottomLeftRadius: '2vw', borderBottomRightRadius: '2vw', overflow: 'hidden' }}>
            <div style={{ position: 'absolute', top: 0, right: 0, backgroundColor: '#ececec', padding: '1vw', borderRadius: '50%', height: '14vw', width: '14vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
              <FontAwesomeIcon icon={faPaperclip} style={{ fontWeight: 500, fontSize: '6vw' }} />
            </div>
            <div style={{ fontSize: '6.5vw', width: '80%', textAlign: 'start', fontWeight: 600, marginTop: '-5vw' }}>
              {t('empowerment_page.cards.card4.title')}
            </div>
            <div style={{ fontSize: '4.5vw', width: '80%', textAlign: 'start', marginTop: '3.5vw' }}>
              {t('empowerment_page.cards.card4.description')}</div>
            <div style={{ position: 'absolute', bottom: '6vw', left: '7vw', display: 'flex', justifyContent: 'center', alignItems: 'center', gap: '0.5vw', fontSize: '4.5vw', textAlign: 'start' }}>
              <FontAwesomeIcon style={{ position: 'relative', color: 'white', backgroundColor: 'black', padding: '1vw', width: '4vw', height: '4vw', borderRadius: '50%', marginRight: '1vw' }} icon={faPlus} />

              {t('empowerment_page.cards.card4.hint')}
            </div>
          </div>

        </div>
      </div>


      <div className={props.scrollPosition > 74 ? 'area show' : 'area'} style={{ position: 'relative', width: '70%', marginTop: '5vw', gap: '2vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <div className='contactBtn' onClick={() => { window.open("https://www.positiveintelligence.com/saboteurs/ ") }} style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', borderRadius: '6vw', paddingTop: '3vw', paddingBottom: '3vw', fontSize: '4vw', fontWeight: 600, cursor: 'pointer' }}>{t('empowerment_page.sabotage_test')}</div>
      </div>

      <div className='flex-start flex-column gap-1' style={{ position: 'relative', width: '100%', marginTop: '6vw', gap: '2vw' }}>
        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '80%', gap: '1vw' }}>
          <div className={props.scrollPosition > 76 ? 'area show' : 'area'} style={{ fontSize: '7vw', fontWeight: 600, width: '90%', textAlign: 'center' }}>
            {t('trainings_page.title')}
          </div>
          <div className={props.scrollPosition > 78 ? 'area show' : 'area'} style={{ fontSize: '5vw', width: '90%', textAlign: 'center', marginTop: '2vw' }}>
            {t('trainings_page.description')}
          </div>
        </div>
        <div className={props.scrollPosition > 80 ? 'area show' : 'area'} style={{ position: 'relative', width: '100%', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', gap: '4vw', marginTop: '10vw' }}>
          <Marquee gradientColor="var(--ytd-background-white-color)" autoFill speed={20} gradient gradientWidth={100} style={{ borderRadius: '0vw', position: 'relative', width: '100%' }}>
            <div className='pulsate' style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vw', width: '35vw', padding: '2vw', borderRadius: '8vw' }}>{t('trainings_page.cards_section1.card1.title')}</div>
            <div className='pulsate' style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vw', width: '35vw', padding: '2vw', borderRadius: '8vw' }}>{t('trainings_page.cards_section1.card2.title')}</div>
            <div className='pulsate' style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vw', width: '35vw', padding: '2vw', borderRadius: '8vw' }}>{t('trainings_page.cards_section1.card3.title')}</div>
            <div className='pulsate' style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vw', width: '35vw', padding: '2vw', borderRadius: '8vw' }}>{t('trainings_page.cards_section1.card4.title')}</div>
            <div className='pulsate' style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vw', width: '35vw', padding: '2vw', borderRadius: '8vw' }}>{t('trainings_page.cards_section1.card5.title')}</div>
            <div className='pulsate' style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vw', width: '35vw', padding: '2vw', borderRadius: '8vw' }}>{t('trainings_page.cards_section1.card6.title')}</div>
          </Marquee>

          <Marquee gradientColor="var(--ytd-background-white-color)" autoFill speed={30} gradient gradientWidth={100} direction='right' style={{ borderRadius: '0vw', position: 'relative', width: '100%', marginTop: '-1vw' }}>
            <div className='pulsate' style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vw', width: '35vw', padding: '2vw', borderRadius: '8vw' }}>{t('trainings_page.cards_section2.card1.title')}</div>
            <div className='pulsate' style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vw', width: '35vw', padding: '2vw', borderRadius: '8vw' }}>{t('trainings_page.cards_section2.card2.title')}</div>
            <div className='pulsate' style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vw', width: '35vw', padding: '2vw', borderRadius: '8vw' }}>{t('trainings_page.cards_section2.card3.title')}</div>
            <div className='pulsate' style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vw', width: '35vw', padding: '2vw', borderRadius: '8vw' }}>{t('trainings_page.cards_section2.card4.title')}</div>
            <div className='pulsate' style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vw', width: '35vw', padding: '2vw', borderRadius: '8vw' }}>{t('trainings_page.cards_section2.card5.title')}</div>
            <div className='pulsate' style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vw', width: '35vw', padding: '2vw', borderRadius: '8vw' }}>{t('trainings_page.cards_section2.card6.title')}</div>
            <div className='pulsate' style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vw', width: '35vw', padding: '2vw', borderRadius: '8vw' }}>{t('trainings_page.cards_section2.card7.title')}</div>
          </Marquee>

          <Marquee gradientColor="var(--ytd-background-white-color)" autoFill speed={60} gradient gradientWidth={100} style={{ borderRadius: '0vw', position: 'relative', width: '100%', marginTop: '-1vw' }}>
            <div className='pulsate' style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vw', width: '35vw', padding: '2vw', borderRadius: '8vw' }}>{t('trainings_page.cards_section3.card1.title')}</div>
            <div className='pulsate' style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vw', width: '35vw', padding: '2vw', borderRadius: '8vw' }}>{t('trainings_page.cards_section3.card2.title')}</div>
            <div className='pulsate' style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vw', width: '35vw', padding: '2vw', borderRadius: '8vw' }}>{t('trainings_page.cards_section3.card3.title')}</div>
            <div className='pulsate' style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vw', width: '35vw', padding: '2vw', borderRadius: '8vw' }}>{t('trainings_page.cards_section3.card4.title')}</div>
            <div className='pulsate' style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vw', width: '35vw', padding: '2vw', borderRadius: '8vw' }}>{t('trainings_page.cards_section3.card5.title')}</div>
            <div className='pulsate' style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', height: '25vw', width: '35vw', padding: '2vw', borderRadius: '8vw' }}>{t('trainings_page.cards_section3.card6.title')}</div>
          </Marquee>
        </div>
      </div>

      <div className='flex-start flex-row gap-1' style={{ position: 'relative', width: '95%', paddingLeft: '1vw', paddingRight: '1vw', marginTop: '10vw', gap: '0vw', zIndex: 3, borderTopLeftRadius: '3vw', borderTopRightRadius: '3vw', display: 'flex', justifyContent: 'start', alignItems: 'center', flexDirection: 'column' }}>
        <div style={{ fontSize: '7vw', fontWeight: 600, marginTop: '0vw' }}>
          {t('meeting_page.title')}
        </div>
        <div style={{ fontSize: '5vw', width: '90%', textAlign: 'center', marginTop: '4vw' }}>
          {t('meeting_page.description')}
        </div>
        <InlineWidget url="https://calendly.com/enevali2016/30min" styles={{ position: 'relative', width: '100%', height: '200vw', marginTop: '10vw' }} />
      </div>

      <div className='flex-start flex-row gap-1' style={{ position: 'relative', width: '100%', overflow: 'hidden', zIndex: 5, marginTop: '-17vw', minHeight: '25vw', gap: '2vw', paddingTop: '0vw', paddingBottom: '0vw', backgroundColor: 'rgba(0,0,0,1)', flexDirection: 'row', borderTopLeftRadius: '6vw', borderTopRightRadius: '6vw', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <img src='background/2.png' style={{ opacity: 1, left: '8vw', position: 'absolute', top: '-55vw', width: '80vw', height: '80vw' }} />

        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center', flexDirection: 'column', width: '100%', gap: '3vw' }}>
          <div style={{ position: 'relative', display: 'inline-flex', justifyContent: 'center', alignItems: 'center' }}>
            <img
              src="logo.png"
              style={{
                maxWidth: '10vw',
                position: 'relative',
                transform: 'scale(2)',
                filter: 'invert(1)'
              }}
            />
            <div
              style={{
                marginRight: '0rem',
                fontWeight: 600,
                width: '100%',
                cursor: 'pointer',
                zIndex: 1,
                position: 'relative',
                left: '4vw',
                fontSize: '4vw',
                color: 'white'
              }}
            >
              YTD Real Consulting
            </div>
          </div>
          <div style={{ color: 'white', width: '100%', textAlign: 'center', marginTop: '-5vw', opacity: 1, fontSize: '2.3vw', fontWeight: 600 }}>All rights reserved</div>
        </div>
      </div>

    </div >
  </>

  );
}

export default NewHomePhone;
